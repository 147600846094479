import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import IconChevronBold from 'ui/components/generic/IconChevronBold'

const steps = {
  '/': 'Symptomer',
  '/info': 'Info',
  '/risikofaktorer': 'Risikofaktorer',
  '/relaterede': 'Relaterede symptomer',
  '/interview': 'Interview',
  '/resultater': 'Resultat',
}

if (!process.env.REACT_APP_ENABLE_RISK_FACTORS) {
  delete steps['/risikofaktorer']
}

class BottomNavigation extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  }

  isActive = (key) => {
    const {
      location: { pathname },
    } = this.props

    if (pathname === '/' && key === '/') {
      return true
    }
    if (key !== '/' && pathname.indexOf(key) === 0) {
      return true
    }
    return false
  }

  back = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    // don't show bottom navigation on these screens
    const disabledScreens = ['/resultater', '/']

    if (
      !Object.keys(steps)
        .filter((s) => !disabledScreens.includes(s))
        .find((key) => this.isActive(key))
    ) {
      return null
    }

    return (
      <Wrapper>
        <Container fluid>
          <Inner>
            <BackButton round onClick={this.back} hollow color="primary">
              <IconChevronBold direction="left" />
            </BackButton>
            <Steps>
              {Object.keys(steps).map((key) => (
                <Step key={key} active={this.isActive(key)}>
                  {steps[key]}
                </Step>
              ))}
            </Steps>
            {/* <Text size="xxs">
              Lereveret af{' '}
              <a href="https://sygeforsikring.dk">sygeforsikring.dk</a>
            </Text> */}
          </Inner>
        </Container>
      </Wrapper>
    )
  }
}

export default withRouter(BottomNavigation)

const entry = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  background-color: ${(props) => props.theme.colors.tones.lightest};
  z-index: 10;

  animation: ${entry} 500ms both;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.theme.navHeight}px;

  border-top: 1px solid rgba(225, 225, 225, 0.5);
`

const BackButton = styled(Button)`
  > svg path {
    fill: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    > svg path {
      fill: #fff;
    }
  }
`

const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Step = styled(Text).attrs({ size: 'xxs' })`
  font-weight: ${(props) =>
    props.theme.fontWeights[props.active ? 'bold' : 'regular']};
  color: ${(props) => (props.active ? props.theme.colors.primary : 'inherit')};

  &:after  {
    content: '>>';
    display: none;
    margin: 0 10px;
    font-weight: 200;
    color: ${(props) => props.theme.colors.tones.light};
    opacity: 0.5;
  }

  display: ${(props) => (props.active ? 'flex' : 'none')};

  ${media.md`
    display: flex;

    &:after {
      display: inline-block;;
    }

    &:last-child {
    &:after {
      display: none;
    }

  `}
`
