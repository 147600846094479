export default {
  displayFont: '"Cabin", sans-serif',
  bodyFont: '"Roboto Slab", Helvetica, serif',
  fontSizes: {
    xxl: {
      xs: {
        fs: 30,
        lh: 35,
        ls: 0
      },
      sm: {
        fs: 46,
        lh: 50,
        ls: 0
      },
      md: {
        fs: 50,
        lh: 55,
        ls: 0
      },
      lg: {
        fs: 50,
        lh: 55,
        ls: 0
      },
      xl: {
        fs: 50,
        lh: 55,
        ls: 0
      }
    },
    xl: {
      xs: {
        fs: 24,
        lh: 30,
        ls: 0
      },
      sm: {
        fs: 30,
        lh: 35,
        ls: 0
      },
      md: {
        fs: 35,
        lh: 45,
        ls: 0
      },
      lg: {
        fs: 35,
        lh: 45,
        ls: 0
      },
      xl: {
        fs: 35,
        lh: 45,
        ls: 0
      }
    },
    lg: {
      xs: {
        fs: 22,
        lh: 26,
        ls: 0
      },
      sm: {
        fs: 22,
        lh: 26,
        ls: 0
      },
      md: {
        fs: 22,
        lh: 26,
        ls: 0
      },
      lg: {
        fs: 28,
        lh: 35,
        ls: 0
      },
      xl: {
        fs: 28,
        lh: 35,
        ls: 0
      }
    },
    md: {
      xs: {
        fs: 18,
        lh: 24,
        ls: 0
      },
      sm: {
        fs: 18,
        lh: 24,
        ls: 0
      },
      md: {
        fs: 22,
        lh: 28,
        ls: 0
      },
      lg: {
        fs: 22,
        lh: 28,
        ls: 0
      },
      xl: {
        fs: 22,
        lh: 28,
        ls: 0
      }
    },
    sm: {
      xs: {
        fs: 16,
        lh: 22,
        ls: 0
      },
      sm: {
        fs: 16,
        lh: 22,
        ls: 0
      },
      md: {
        fs: 18,
        lh: 24,
        ls: 0
      },
      lg: {
        fs: 18,
        lh: 24,
        ls: 0
      },
      xl: {
        fs: 18,
        lh: 24,
        ls: 0
      }
    },
    xs: {
      xs: {
        fs: 12,
        lh: 16,
        ls: 0
      },
      sm: {
        fs: 12,
        lh: 16,
        ls: 0
      },
      md: {
        fs: 14,
        lh: 18,
        ls: 0
      },
      lg: {
        fs: 14,
        lh: 18,
        ls: 0
      },
      xl: {
        fs: 14,
        lh: 18,
        ls: 0
      }
    },
    xxs: {
      xs: {
        fs: 12,
        lh: 16,
        ls: 0
      },
      sm: {
        fs: 12,
        lh: 16,
        ls: 0
      },
      md: {
        fs: 12,
        lh: 16,
        ls: 0
      },
      lg: {
        fs: 12,
        lh: 16,
        ls: 0
      },
      xl: {
        fs: 12,
        lh: 16,
        ls: 0
      }
    }
  },
  fontWeights: {
    thin: 100,
    light: 200,
    normal: 400,
    bold: 600,
    black: 700
  },
  colors: {
    tones: {
      lightest: '#F5F5F5',
      lighter: '#E1E1E1',
      light: '#969696',
      dark: '#373737',
      darker: '#323232'
    },
    primary: '#FF0049',
    primaryDark: '#bd0439',
    secondary: '#2D0074',
    severity: {
      mild: '#448444',
      moderate: '#E0BF4A',
      severe: '#7A2929'
    },
    danger: '#7A2929',
    triage: {
      self_care: '#448444',
      consultation: '#448444',
      consultation_24: '#E0BF4A',
      emergency: '#7A2929',
      emergency_ambulance: '#7A2929'
    },
    sst: 'rgb(11,92, 139)'
  },
  spacing: {
    xs: 10,
    sm: 10,
    md: 15,
    lg: 20,
    xl: 20
  },
  navHeight: 60,
  navHeightXs: 100,
  grid: {
    breakpoints: {
      // defaults below
      giant: 1200,
      xl: 1200,
      desktop: 992,
      lg: 992,
      tablet: 768,
      md: 768,
      phone: 576,
      sm: 576,
      smaller: 575,
      xs: 575
    },
    row: {
      padding: 10 // default 15
    },
    col: {
      padding: 10 // default 15
    },
    container: {
      padding: 30, // default 15
      paddingXs: 20
    }
  }
}
