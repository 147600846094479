import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { compose } from 'redux'

import withSymptoms from 'containers/withSymptoms'
import getTranslation from 'utils/getTranslation'
import withTranslations from 'containers/withTranslations'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Question = styled(Text).attrs({ as: 'p', size: 'sm' })`
  margin-bottom: 30px
  text-align: center;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    flex-direction: row;
  `}

  button {
    margin-left: 0 !important;
    margin-bottom: 10px;

    ${media.md`
      margin-left: 10px !important;

      &:first-child {
        margin-left: 0 !important;
      }
    `}
  }
`

class SingleQuestion extends Component {
  static propTypes = {
    text: PropTypes.string,
    items: PropTypes.array,
    onAnswer: PropTypes.func,
    getChoiceId: PropTypes.func,
    translations: PropTypes.array
  }

  render() {
    const { text, items, onAnswer, getChoiceId, translations } = this.props

    const id = items.length === 1 ? items[0].id : text

    return (
      <PaddedContent>
        <Wrapper>
          <Question>
            {getTranslation(id, translations, 'question') || text}
          </Question>
          {items.map(item => (
            <Options>
              {item.choices.map(choice => (
                <Button
                  id={choice.id}
                  onClick={() => onAnswer(item, choice.id)}
                  color={
                    getChoiceId(item) === choice.id ? 'primaryDark' : 'primary'
                  }
                >
                  {getTranslation(choice.label, translations) || choice.label}
                </Button>
              ))}
            </Options>
          ))}
        </Wrapper>
      </PaddedContent>
    )
  }
}

export default compose(withSymptoms, withTranslations())(SingleQuestion)
