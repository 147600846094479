import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default (
  getPatientInfo = props => props.patientInfo,
  getSymptoms = props => props.symptoms,
  key = 'diagnosis',
  covid19
) => WrappedComponent =>
  class WithDiagnosis extends Component {
    render() {
      const patientInfo = getPatientInfo(this.props)
      const age = patientInfo.age
      const sex = patientInfo.gender
      const interviewId = patientInfo.interviewId
      const evidence = getSymptoms(this.props)
        .filter(symptom => !!symptom.choiceId)
        .map(symptom => {
          const ev = {
            id: symptom.id,
            choice_id: symptom.choiceId
          }

          // Only add source if it has a value
          if (symptom.source) {
            ev.source = symptom.source
          }

          if (covid19) {
            delete ev.initial
            delete ev.related
          }
          return ev
        })

      if (!age || !sex || (evidence.length === 0 && !covid19)) {
        return <WrappedComponent {...this.props} />
      }

      return (
        <Query
          variables={{
            input: {
              age: {
                value: age
              },
              sex,
              evidence,
              interviewId,
              extras: { disable_groups: false, enable_explanations: true }
            }
          }}
          query={gql`
            query diagnosis {
              diagnosis(input: $input)
                @rest(type: "Diagnosis", path: "diagnosis/", method: "POST") {
                question @type(name: "Question") {
                  type
                  text
                  explication
                  instruction
                  items @type(name: "QuestionItem") {
                    id
                    name
                    explication
                    instruction
                    choices @type(name: "QuestionChoice") {
                      id
                      label
                    }
                  }
                }
                should_stop
                has_emergency_evidence
                conditions @type(name: "Condition") {
                  id
                  common_name
                  name
                  probability

                  # details
                  description
                  descriptionSource
                  link
                  linkSource
                  type
                }
              }
            }
          `}
          context={covid19 ? { headers: { 'covid-19-mode': true } } : undefined}
          ssr={false}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data[key]
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
