import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import { push } from 'utils/gtm'
import withDiagnosis from 'containers/withDiagnosis'
import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'

import PaddedContent from 'ui/components/generic/PaddedContent'

import Triage from './Triage'
import SST from './SST'

class Results extends Component {
  static propTypes = {
    diagnosis: PropTypes.oneOfType([
      PropTypes.shape({
        conditions: PropTypes.array
      }),
      PropTypes.bool
    ]),
    reset: PropTypes.func,
    symptoms: PropTypes.array
  }

  componentDidMount() {
    this.track()
  }

  track() {
    const { diagnosis, symptoms } = this.props

    // Track no matching conditions
    if (diagnosis) {
      const conditions = diagnosis.conditions.filter(
        condition => condition.probability > 0.2
      )
      if (conditions.length === 0) {
        push({ event: 'noMatchingConditions', symptoms })
      } else {
        push({ event: 'matchingConditions', conditions, symptoms })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.diagnosisLoading && this.props.diagnosis) {
      this.track()
    }
  }

  reset = () => {
    this.props.reset()
  }

  render() {
    return (
      <Wrapper>
        <PaddedContent>
          <Triage />
          <SST />
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withDiagnosis()
)(Results)

const Wrapper = styled.div`
  padding-top: ${props => props.theme.navHeight + 30}px;
  padding-bottom: 30px;
`
