import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import getTranslation from 'utils/getTranslation'
import withTranslations from 'containers/withTranslations'
import ButtonComponent from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'

class GroupMultipleQuestion extends Component {
  static propTypes = {
    translations: PropTypes.array,
    items: PropTypes.array,
    onAnswer: PropTypes.func,
    text: PropTypes.string
  }

  state = {
    answers: {}
  }

  constructor(props) {
    super(props)
    this.state = { answers: this.getAnswersFromProps(props) }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.getAnswersFromProps()) !==
      JSON.stringify(this.getAnswersFromProps(prevProps))
    ) {
      this.setState({
        answers: this.getAnswersFromProps()
      })
    }
  }

  getAnswersFromProps(props = this.props) {
    const { items, getChoiceId } = props
    const answers = {}

    items.forEach(item => {
      const choiceId = getChoiceId(item)
      answers[item.id] = {
        symptom: item,
        choiceId: choiceId
      }
    })

    return answers
  }

  answer = (symptom, choiceId) => {
    const { answers } = this.state
    const newAnswers = {
      ...answers,
      [symptom.id]: { symptom, choiceId }
    }
    this.setState({ answers: newAnswers })
  }

  continue = () => {
    const { answers } = this.state
    const { items, onAnswer } = this.props

    if (Object.keys(answers).length === items.length) {
      onAnswer(Object.keys(answers).map(id => answers[id]))
    }
  }

  render() {
    const { answers } = this.state

    const { text, items, translations } = this.props

    const canContinue = Object.keys(answers).every(
      key => answers[key].choiceId !== null
    )

    return (
      <Wrapper>
        <PaddedContent>
          <Text mb={30} size="sm">
            {getTranslation(text, translations) || text}
          </Text>
          <Options>
            {items.map(item => (
              <Option key={item.id}>
                <OptionText>
                  {getTranslation(item.id, translations) || item.name}
                </OptionText>
                <OptionChoices>
                  <Button
                    size="small"
                    onClick={() =>
                      this.answer(
                        item,
                        answers[item.id] &&
                          answers[item.id].choiceId === 'present'
                          ? null
                          : 'present'
                      )
                    }
                    color={
                      answers[item.id] &&
                      answers[item.id].choiceId === 'present'
                        ? 'primary'
                        : undefined
                    }
                  >
                    Ja
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      this.answer(
                        item,
                        answers[item.id] &&
                          answers[item.id].choiceId === 'absent'
                          ? null
                          : 'absent'
                      )
                    }
                    color={
                      answers[item.id] && answers[item.id].choiceId === 'absent'
                        ? 'primary'
                        : undefined
                    }
                  >
                    Nej
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      this.answer(
                        item,
                        answers[item.id] &&
                          answers[item.id].choiceId === 'unknown'
                          ? null
                          : 'unknown'
                      )
                    }
                    color={
                      answers[item.id] &&
                      answers[item.id].choiceId === 'unknown'
                        ? 'primary'
                        : undefined
                    }
                  >
                    Ved ikke
                  </Button>
                </OptionChoices>
              </Option>
            ))}
          </Options>
          <Button
            color="primary"
            disabled={!canContinue}
            onClick={this.continue}
          >
            Videre
          </Button>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default withTranslations()(GroupMultipleQuestion)

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
`

const Button = styled(ButtonComponent)`
  ${props =>
    props.faded &&
    css`
      opacity: 0.5;
    `}
`
const Options = styled.div`
  margin-bottom: 20px;
`

const Option = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 50px;
  margin-bottom: 10px;
  color: ${props => (props.selected ? '#fff' : 'inherit')};

  transition: 300ms;
`

const OptionText = styled(Text).attrs({ size: 'xs' })`
  text-align: left;
  padding-right: 45px;
`

const OptionChoices = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  /* position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px; */
  /* padding-right: 20px; */
  height: 100%;
`
