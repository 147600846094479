import React from 'react'

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="101"
    viewBox="0 0 109 101"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FEFEFE"
        d="M55.695 97.263l-2.854-1.648 6.814-11.803.72-.151a12.334 12.334 0 008.141-5.884l17.025-29.488a2.405 2.405 0 00.243-1.841 2.414 2.414 0 00-1.13-1.474 2.43 2.43 0 00-3.302.865l-7.144 12.375-2.855-1.649 7.131-12.35.02-.035 2.017-3.494a2.41 2.41 0 00.243-1.841 2.408 2.408 0 00-1.13-1.474 2.43 2.43 0 00-3.31.881L67.15 54.139l-2.854-1.648 9.168-15.88a.075.075 0 01.007-.012l2.03-3.517a2.41 2.41 0 00.243-1.841 2.43 2.43 0 00-4.446-.585L60.093 50.064l-2.855-1.648 9.168-15.879a2.412 2.412 0 00.243-1.842 2.43 2.43 0 00-4.446-.585L46.428 57.433a3.7 3.7 0 01-4.09 1.732 3.7 3.7 0 01-2.785-3.461l-.35-11.359c-.055-1.675-1.284-3.069-2.799-3.172a2.991 2.991 0 00-2.307.835 3.015 3.015 0 00-.919 2.263c.155 4.823.775 23.106.913 24.406.31 1.802.972 3.078 1.865 3.593l1.427.825-5.887 10.197-2.855-1.649 4.364-7.557c-1.075-1.143-1.815-2.801-2.168-4.886-.184-1.082-.945-24.586-.953-24.823a6.315 6.315 0 011.923-4.74 6.332 6.332 0 014.821-1.753c3.182.217 5.76 3.009 5.868 6.357l.351 11.362a.391.391 0 00.295.365.392.392 0 00.431-.183L59.35 28.462a5.73 5.73 0 019.712-.326 5.733 5.733 0 017.2-1.223 5.68 5.68 0 012.666 3.475 5.676 5.676 0 01-.126 3.375 5.706 5.706 0 012.48.753 5.69 5.69 0 012.667 3.475 5.687 5.687 0 01-.126 3.376c.848.055 1.695.3 2.48.753a5.69 5.69 0 012.666 3.475 5.688 5.688 0 01-.572 4.342L71.371 79.426a15.634 15.634 0 01-9.585 7.288l-6.09 10.549z"
      ></path>
      <path
        fill="#AEDEF8"
        d="M37.439 12.392C25.649 7.112 13.154 4.313.298 4.074L.374 0c13.407.25 26.438 3.168 38.73 8.673l-1.665 3.719zM24.961 34.238A95.593 95.593 0 00.145 28.493l.381-4.057a99.643 99.643 0 0125.874 5.99l-1.439 3.812zM12.3 56.403A92.942 92.942 0 000 53.473l.673-4.02a97.256 97.256 0 0112.84 3.06l-1.213 3.89zm13.842 13.638l-5.636-3.178a4.53 4.53 0 01-2.16-2.77 4.53 4.53 0 01.45-3.486l4.893-8.476 3.82 2.205-4.895 8.476c-.008.014-.032.055-.015.117a.145.145 0 00.072.092l5.637 3.18-2.166 3.84zM74.449 97.02a4.582 4.582 0 01-2.18-.556l-8.198-4.42 2.094-3.882 8.198 4.421a.158.158 0 00.212-.06l30.044-52.054a.166.166 0 00.017-.133.165.165 0 00-.082-.106L52.819 10.721a.121.121 0 00-.165.045L38.902 34.601l-3.82-2.204L48.835 8.562a4.541 4.541 0 016.169-1.672l51.734 29.51a4.55 4.55 0 012.154 2.783 4.547 4.547 0 01-.454 3.49L78.394 94.727a4.545 4.545 0 01-3.945 2.292"
      ></path>
      <path
        fill="#FEFEFE"
        d="M57.273 100.07l-30.64-17.675-9.852 17.072-2.855-1.647 11.499-19.927L58.92 97.216z"
      ></path>
    </g>
  </svg>
)
