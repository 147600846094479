import { push } from 'utils/gtm'

export default store => next => action => {
  const { type } = action

  switch (type) {
    default:
      push({
        event: 'reduxAction',
        action
      })
  }
  const result = next(action)
  return result
}
