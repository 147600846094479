import { combineReducers } from 'redux'

import media from './media'
import overlay from './overlay'
import patientInfo from './patientInfo'
import symptoms from './symptoms'

export default combineReducers({
  media,
  overlay,
  patientInfo,
  symptoms
})
