import { ReverseConfig } from '../types'

const config: ReverseConfig = {
  title: 'Type-2-Diabetes',
  conditionId: 'c_405',
  probabilityThreshold: 0.4,
  text: {
    highProbability:
      'Baseret på dine svar <em>er der en risiko</em> for, at du lider af Type-2-diabetes. Kontakt din læge for at høre nærmere. Du kan læse mere om Diabetes på <a href="https://diabetes.dk">diabetesforeningens hjemmeside</a>',
    mediumProbability:
      'Baseret på dine svar <em>er der en mindre risiko</em> for, at du lider af Type-2-diabetes. Kontakt din læge for at høre nærmere. Du kan læse mere om Diabetes på <a href="https://diabetes.dk">diabetesforeningens hjemmeside</a>',
    lowProbability:
      'Baseret på dine svar <em>er der umiddelbart intet der tyder på</em>, at du lider af Type-2-diabetes. Du kan læse mere om Diabetes på <a href="https://diabetes.dk">diabetesforeningens hjemmeside</a>',
  },
  initialSymptoms: [
    {
      id: 's_310',
      name: 'Forøget tørst',
      question: 'Oplever du forøget tørst?',
    },
    {
      id: 's_215',
      name: 'Hyppige vandladninger',
      question: 'Oplever du hyppige vandladninger?',
    },
    {
      id: 's_13',
      name: 'Mavesmerter',
      question: 'Har du ofte mavesmerter?',
    },
    {
      id: 's_156',
      name: 'Kvalme',
      question: 'Har du ofte kvalme?',
    },
    {
      id: 's_284',
      name: 'Formindsket appetit',
      question: 'Har du formindsket appetit?',
    },
    {
      id: 's_285',
      name: 'Vægttab',
      question: 'Oplever du vægttab?',
    },
    {
      id: 's_21',
      name: 'Hovedpine',
      question: 'Har du ofte hovedpine?',
    },
    {
      id: 's_2100',
      name: 'Træthed',
      question: 'Føler du dig ofte træt?',
    },
    {
      id: 's_505',
      name: 'Hyppige infektioner',
      question: 'Har du tendens til at få infektioner?',
    },
    {
      id: 's_253',
      name: 'Kløe i skridtet',
      question: 'Oplever du kløe omkring kønsorganerne?',
    },
    // {
    //   id: 'xxx',
    //   name: 'Almen utilpashed',
    //   question: 'Oplever du almen utilpashed?',
    // },
  ],
}

export default config
