import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media, Container, Row, Col } from 'styled-bootstrap-grid'

import responsiveSpacing from 'ui/styles/responsiveSpacing'
import UspSlider from './UspSlider'

class InfoBar extends Component {
  static propTypes = {
    visible: PropTypes.bool
  }

  render() {
    const { visible } = this.props

    return (
      <Wrapper visible={visible}>
        <Avatar src="/images/oda.png" visible={visible} />
        <Certificates visible={visible}>
          <Certificate>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                  fill="#0059B2"
                  d="M11.177 23.252C9.118 23.252 8 21.972 8 20.16v-.068C8 18.245 9.355 17 11.177 17c1.432 0 2.702.652 2.82 2.228h-1.719c-.085-.568-.449-.864-1.093-.864-.855 0-1.372.66-1.372 1.754v.067c0 .983.364 1.745 1.415 1.745.847 0 1.169-.432 1.203-.914h-1.136v-1.212h2.847v.746c0 1.541-1.008 2.702-2.965 2.702zm5.557-1.44h.398c1.034 0 1.491-.542 1.491-1.669v-.068c0-1.152-.457-1.643-1.508-1.643h-.38v3.38h-.001zM14.99 23.15v-6.057h2.203c2.151 0 3.244 1.076 3.244 2.957v.068c0 1.889-1.118 3.032-3.253 3.032H14.99zm6.294 0v-6.057h2.305c1.66 0 2.431.737 2.431 2.025v.034c0 1.27-.856 1.957-2.355 1.957h-.652v2.041h-1.729zm1.729-3.227h.5c.559 0 .855-.263.855-.763v-.034c0-.516-.305-.728-.847-.728h-.508v1.525zm3.795 3.227v-6.057h2.372c1.584 0 2.465.644 2.465 1.923v.034c0 .864-.457 1.347-1.093 1.593L32 23.15h-1.83l-1.228-2.168h-.407v2.168h-1.728.001zm1.728-3.295h.56c.584 0 .872-.246.872-.737v-.034c0-.491-.305-.686-.864-.686h-.568v1.457z"
                  transform="translate(-12 -7) translate(12 7)"
                ></path>
                <path
                  fill="#FFC22B"
                  d="M19.5 0l.59 1.91H22l-1.545 1.18.59 1.91L19.5 3.819l-1.544 1.18.59-1.909L17 1.91h1.91L19.5 0zm0 35l.59 1.91H22l-1.545 1.18.59 1.91-1.545-1.18L17.956 40l.59-1.91L17 36.91h1.91L19.5 35zm10-32l.59 1.91H32l-1.545 1.18.59 1.91L29.5 6.82 27.955 8l.59-1.91L27 4.91h1.91L29.5 3zm6 6l.59 1.91H38l-1.546 1.18.591 1.91-1.545-1.18L33.956 14l.59-1.91L33 10.91h1.91L35.5 9zm1 17l.59 1.91H39l-1.545 1.18.59 1.91-1.545-1.18L34.955 31l.59-1.91L34 27.91h1.91L36.5 26zM4.5 9l.59 1.91H7l-1.545 1.18.59 1.91L4.5 12.82 2.955 14l.59-1.91L2 10.91h1.91L4.5 9zm33 8l.59 1.91H40l-1.545 1.18.59 1.91-1.545-1.18L35.955 22l.591-1.91L35 18.91h1.91L37.5 17zm-35 0l.59 1.91H5l-1.545 1.18.59 1.91-1.544-1.18L.955 22l.59-1.91L0 18.91h1.91L2.5 17zm1 9l.59 1.91H6l-1.545 1.18.59 1.91L3.5 29.82 1.956 31l.59-1.91L1 27.91h1.91L3.5 26zm26 6l.59 1.91H32l-1.546 1.18.59 1.91-1.544-1.18L27.954 37l.591-1.91L27 33.91h1.91L29.5 32zm-19-29l.59 1.91H13l-1.546 1.18.59 1.91L10.5 6.82 8.955 8l.59-1.91L8 4.91h1.91L10.5 3zm-1 29l.59 1.91H12l-1.545 1.18.59 1.91L9.5 35.82 7.955 37l.59-1.91L7 33.91h1.91L9.5 32z"
                  transform="translate(-12 -7) translate(12 7)"
                ></path>
              </g>
            </svg>
            <CertificateLabel>
              Vi overholder EUs
              <br />
              GDPR-regulativer
            </CertificateLabel>
          </Certificate>
        </Certificates>
        <Container fluid>
          <Row alignItems="center">
            <Col sm={12}>
              <UspSlider />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default InfoBar

const Avatar = styled.img`
  position: absolute;
  bottom: 100%;
  width: 100px;
  left: 30px;
  pointer-events: none;
  transition: 1000ms;

  ${media.md`
    left: 20vw;
    width: 140px;
  `}

  ${media.xs`
    opacity: ${props => (props.visible ? 1 : 0)};
  `}
`

const Certificates = styled.div`
  position: absolute;
  right: 0px;
  bottom: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  padding: 0px;
  border-top-left-radius: 4px;
  transition: 1000ms;

  ${media.xs`
    display: none;
  `}

  transform: translateX(${props => (props.visible ? 0 : 100)}%);
`

const Certificate = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  height: 36px;
  border-right: 1px solid ${props => props.theme.colors.tones.lightest};

  &:last-child {
    border: none;
  }

  svg {
    width: 26px;
  }
`

const CertificateLabel = styled.div`
  font-weight: ${props => props.theme.fontWeights.normal};
  color: ${props => props.theme.colors.tones.dark};
  font-size: 9px;
  margin-left: 12px;
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  background-color: ${props => props.theme.colors.primary};
  ${responsiveSpacing(1, 'padding-top')}
  ${responsiveSpacing(1, 'padding-bottom')}

  color: #fff;

  transform: translateY(${props => (props.visible ? 0 : 100)}%);
  transition: 500ms;
`
