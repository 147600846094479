import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'redux'

import withSiteMeta from 'containers/withSiteMeta'
import ContentPage from 'ui/components/ContentPage'
import Terms from 'ui/components/views/Terms'
import SuggestSymptom from 'ui/components/views/SuggestSymptom'
import EarlyTriage from 'ui/components/views/EarlyTriage'

class Overlay extends Component {
  getContent() {
    const { content, data, siteMeta } = this.props

    switch (content) {
      case 'about':
        return <ContentPage slug={siteMeta.aboutSlug} />
      case 'terms':
        return <Terms />
      case 'suggestSymptom':
        return <SuggestSymptom search={data} />
      case 'earlyTriage':
        return <EarlyTriage />
      default:
        return null
    }
  }

  render() {
    const { visible } = this.props
    const content = this.getContent()

    return (
      <Wrapper visible={visible} id="overlay">
        {content}
      </Wrapper>
    )
  }
}

Overlay.propTypes = {
  visible: PropTypes.bool,
  content: PropTypes.string,
  siteMeta: PropTypes.shape({
    aboutSlug: PropTypes.string
  })
}

export default compose(
  withSiteMeta(),
  connect(state => ({
    visible: state.overlay.visible,
    content: state.overlay.content,
    data: state.overlay.data
  }))
)(Overlay)

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 19;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  padding-bottom: ${props => props.theme.navHeight}px;

  /* background-color: rgba(255, 255, 255, 0.95); */
  background-color: ${props => props.theme.colors.tones.lightest};

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  transition: all 800ms;
`
