import React from 'react'

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117"
    height="102"
    viewBox="0 0 117 102"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M.008 43.996v25.466a7.346 7.346 0 002.165 5.231 7.35 7.35 0 005.231 2.167 7.348 7.348 0 003.63-.958v25.651h3.77V48.981h-3.77v20.48c0 2-1.629 3.628-3.63 3.628-.969 0-1.88-.377-2.565-1.062a3.603 3.603 0 01-1.06-2.565V43.996c0-6.578 5.351-11.93 11.93-11.93v-3.77c-8.658 0-15.7 7.042-15.7 15.7m34.088-15.701v3.771c6.578 0 11.93 5.352 11.93 11.93v25.467a3.596 3.596 0 01-1.06 2.564 3.604 3.604 0 01-2.567 1.062c-2 0-3.627-1.628-3.627-3.628v-20.48H35l.001 20.48v32.092h3.77V75.902a7.347 7.347 0 003.628.958c1.977 0 3.835-.77 5.232-2.166a7.35 7.35 0 002.166-5.232V43.996c0-8.658-7.043-15.701-15.7-15.701M23.018 101.553h3.771V74.975h-3.771zM24.903.892c-7.075 0-12.83 5.756-12.83 12.831 0 7.074 5.755 12.83 12.83 12.83s12.831-5.756 12.831-12.83c0-7.075-5.756-12.83-12.831-12.83m0 21.89c-4.996 0-9.06-4.064-9.06-9.06 0-4.997 4.064-9.06 9.06-9.06s9.059 4.063 9.059 9.06c0 4.996-4.063 9.06-9.059 9.06"
      ></path>
      <path
        fill="#9EDBFA"
        d="M69.042 14.244a7.373 7.373 0 012.174 5.245 7.375 7.375 0 01-2.174 5.246 7.366 7.366 0 01-5.245 2.172v3.77c2.99 0 5.8-1.164 7.912-3.277a11.113 11.113 0 003.276-7.911 11.12 11.12 0 00-3.276-7.912c-4.362-4.361-11.459-4.362-15.823 0l2.666 2.667c2.892-2.893 7.598-2.891 10.49 0m15.956 31.909c0-7.434-6.048-13.482-13.482-13.482v3.77c5.355 0 9.71 4.357 9.71 9.712l.002 21.053a2.745 2.745 0 01-2.742 2.743 2.745 2.745 0 01-2.743-2.743V50.015h-3.771v44.13h3.77v-21.04a6.47 6.47 0 002.744.615 6.52 6.52 0 006.513-6.514l-.001-21.053zM61.912 94.145h3.771V71.834h-3.771zM102.75 20.133a6.028 6.028 0 010 8.516 5.98 5.98 0 01-4.26 1.763v3.771a9.733 9.733 0 006.927-2.868c3.817-3.818 3.817-10.032 0-13.85-3.82-3.817-10.032-3.815-13.85 0l2.667 2.667a6.025 6.025 0 018.516 0m2.301 15.876v3.77c4.397 0 7.973 3.577 7.973 7.974V64.95a2.05 2.05 0 01-2.047 2.049 2.05 2.05 0 01-2.049-2.05V50.338h-3.77v37.51h3.77v-17.47a5.77 5.77 0 002.05.392 5.826 5.826 0 005.817-5.82V47.752c0-6.476-5.268-11.744-11.744-11.744M96.606 87.848h3.771V68.885h-3.771z"
      ></path>
    </g>
  </svg>
)
