import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import withCovid19 from 'containers/withCovid19'
import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Text from 'ui/components/generic/Text'

class Home extends Component {
  static propTypes = {
    covid19: PropTypes.shape({
      introHeader: PropTypes.string,
      introBody: PropTypes.string
    })
  }

  render() {
    const { introHeader, introBody } = this.props.covid19

    return (
      <Wrapper>
        <PaddedContent>
          <h1 dangerouslySetInnerHTML={{ __html: introHeader }} />
          <Body dangerouslySetInnerHTML={{ __html: introBody }} size="xs" />
          <Button to={'/covid19/patient-info/koen'} color="primary">
            Start
          </Button>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default withCovid19()(Home)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.navHeight}px 0;
  min-height: 100vh;
`

const Body = styled(Text).attrs({ size: 'xs' })`
  p {
    margin-bottom: 10px;
  }

  margin-bottom: 30px;
`
