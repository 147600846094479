import React, { Component } from 'react'
import styled from 'styled-components'
import { compose } from 'redux'

import theme from 'ui/styles/theme'
import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'

import withPatientInfo from 'containers/withPatientInfo'
import withSubmitRating from 'containers/withSubmitRating'
import responsiveText from 'ui/styles/responsiveText'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import Spinner from 'ui/components/generic/Spinner'
import IconStar from 'ui/components/generic/IconStar'
import times from 'lodash/times'

class Feedback extends Component {
  static propTypes = {}
  state = {
    hover: null,
    rating: null,
    comment: ''
  }

  submit = () => {
    const {
      patientInfo: { interviewId },
      submitRating
    } = this.props
    const { rating, comment } = this.state

    if (interviewId && (rating !== null || comment)) {
      submitRating({
        variables: {
          input: {
            interviewId,
            rating,
            comment
          }
        }
      })
    }
  }

  render() {
    const { submitRatingLoading, submitRatingResult } = this.props
    const { hover, rating, comment } = this.state

    const valid = rating !== null

    const done = submitRatingResult && submitRatingResult.success

    return (
      <Wrapper>
        <Box>
          <Header>Hjælp os med at blive bedre</Header>
        </Box>
        <SlideInOutGroup>
          {!submitRatingLoading && !done && (
            <SlideInOut key="form" direction="left" amount={20}>
              <Box>
                <SubHeader>Hvordan var din oplevelse med servicen?</SubHeader>
                <Stars>
                  {times(5, i => (
                    <Star
                      key={i}
                      onMouseEnter={() => this.setState({ hover: i })}
                      onMouseLeave={() => this.setState({ hover: null })}
                      onClick={() =>
                        this.setState({ rating: rating === i ? null : i })
                      }
                    >
                      <IconStar
                        fill={
                          (hover !== null && hover >= i) ||
                          (rating !== null && rating >= i)
                            ? theme.colors.primary
                            : 'transparent'
                        }
                        stroke={theme.colors.primary}
                      />
                    </Star>
                  ))}
                </Stars>
                <SubHeader>
                  Uddyb gerne din feedback, hvis du har lyst
                </SubHeader>
                <Input
                  onChange={e => this.setState({ comment: e.target.value })}
                  value={comment}
                  placeholder="Skriv din kommentar her.."
                />
                <Button
                  color="primary"
                  disabled={!valid}
                  onClick={valid ? this.submit : undefined}
                >
                  Send feedback
                </Button>
                {submitRatingLoading && <Spinner />}
              </Box>
            </SlideInOut>
          )}
          {!submitRatingLoading && done && (
            <SlideInOut key="success" direction="left" amount={20}>
              <Box>
                <Text size="xs">Mange tak for din feedback.</Text>
              </Box>
            </SlideInOut>
          )}
        </SlideInOutGroup>
      </Wrapper>
    )
  }
}

export default compose(withSubmitRating(), withPatientInfo)(Feedback)

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Box = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 1px;
  overflow: hidden;

  min-height: ${props => (props.fixHeight ? 300 : 0)}px;
`

const Header = styled(Text).attrs({
  as: 'h2'
})`
  margin-bottom: 0;
`

const SubHeader = styled(Text).attrs({
  as: 'h4',
  size: 'xs'
})`
  margin-bottom: 15px;
  /* font-weight: normal; */
`

const Stars = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

const Star = styled.div`
  padding: 0 3px;
  cursor: pointer;
`

const Input = styled.textarea.attrs({ rows: 4 })`
  border: 1px solid ${props => props.theme.colors.tones.lightest};
  margin-bottom: 20px;
  padding: 20px;
  ${responsiveText('xs')}
`
