import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import withTranslations from 'containers/withTranslations'
import ButtonComponent from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Explication from 'ui/components/generic/Explication'

class GroupMultipleQuestion extends Component {
  static propTypes = {
    items: PropTypes.array,
    onAnswer: PropTypes.func,
    text: PropTypes.string,
  }

  state = {
    answers: {},
  }

  constructor(props) {
    super(props)
    this.state = { answers: this.getAnswersFromProps(props) }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.getAnswersFromProps()) !==
      JSON.stringify(this.getAnswersFromProps(prevProps))
    ) {
      this.setState({
        answers: this.getAnswersFromProps(),
      })
    }
  }

  getAnswersFromProps(props = this.props) {
    const { items, getChoiceId } = props
    const answers = {}

    items.forEach((item) => {
      const choiceId = getChoiceId(item)
      answers[item.id] = {
        symptom: item,
        choiceId: choiceId,
      }
    })

    return answers
  }

  answer = (symptom, choiceId) => {
    const { answers } = this.state
    const newAnswers = {
      ...answers,
      [symptom.id]: { symptom, choiceId },
    }
    this.setState({ answers: newAnswers })
  }

  continue = () => {
    const { answers } = this.state
    const { items, onAnswer } = this.props

    if (Object.keys(answers).length === items.length) {
      onAnswer(Object.keys(answers).map((id) => answers[id]))
    }
  }

  render() {
    const { answers } = this.state

    const { text, items, explication } = this.props

    const canContinue = Object.keys(answers).every(
      (key) => answers[key].choiceId !== null
    )

    return (
      <Wrapper>
        <PaddedContent>
          <Text mb={30} size="sm">
            {text}
            {explication && <Explication text={explication} />}
          </Text>
          <Options>
            {items.map((item) => (
              <Option key={item.id}>
                <OptionText>
                  {item.question || item.common_name || item.name}
                  {explication && <Explication text={explication} />}
                </OptionText>
                <OptionChoices>
                  <Button
                    size="small"
                    onClick={() =>
                      this.answer(
                        item,
                        answers[item.id] &&
                          answers[item.id].choiceId === 'present'
                          ? null
                          : 'present'
                      )
                    }
                    color={
                      answers[item.id] &&
                      answers[item.id].choiceId === 'present'
                        ? 'primary'
                        : undefined
                    }
                  >
                    Ja
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      this.answer(
                        item,
                        answers[item.id] &&
                          answers[item.id].choiceId === 'absent'
                          ? null
                          : 'absent'
                      )
                    }
                    color={
                      answers[item.id] && answers[item.id].choiceId === 'absent'
                        ? 'primary'
                        : undefined
                    }
                  >
                    Nej
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      this.answer(
                        item,
                        answers[item.id] &&
                          answers[item.id].choiceId === 'unknown'
                          ? null
                          : 'unknown'
                      )
                    }
                    color={
                      answers[item.id] &&
                      answers[item.id].choiceId === 'unknown'
                        ? 'primary'
                        : undefined
                    }
                  >
                    Ved ikke
                  </Button>
                </OptionChoices>
              </Option>
            ))}
          </Options>
          <Button
            color="primary"
            disabled={!canContinue}
            onClick={this.continue}
          >
            Videre
          </Button>
          <Instruction visible={!canContinue}>
            <span>Angiv et svar på hver linje</span>
          </Instruction>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default withTranslations()(GroupMultipleQuestion)

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
`

const Button = styled(ButtonComponent)`
  & + & {
    margin-left: 10px;
  }

  ${(props) =>
    props.faded &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      min-width: 0;
      padding-left: 15px;
      padding-right: 15px;
    `}
`
const Options = styled.div`
  margin-bottom: 30px;
`

const Option = styled.div`
  position: relative;

  padding: 15px 0;
  color: ${(props) => (props.selected ? '#fff' : 'inherit')};
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);

  transition: 300ms;

  ${media.md`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

const OptionText = styled(Text).attrs({ size: 'xs' })`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.md`
    text-align: left;
    padding-right: 45px;
    margin-bottom: 0;
  `}
`

const OptionChoices = styled.div`
  height: 100%;

  ${media.md`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
  `}
`

const Instruction = styled(Text).attrs({ size: 'xxs' })`
  margin-top: 20px;
  color: ${(props) => props.theme.colors.tones.light};
  font-style: italic;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 400ms;
`
