import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

// import { push } from 'utils/gtm'
import IconSundhedDK from 'ui/components/generic/IconSundhedDK'
import Text from 'ui/components/generic/Text'
import IconCheck from 'ui/components/generic/IconCheck'
import IconMinus from 'ui/components/generic/IconMinus'
import Spinner from 'ui/components/generic/Spinner'
import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'
import withConditionExplanation from 'containers/withConditionExplanation'
import withCondition from 'containers/withCondition'
import theme from 'ui/styles/theme'
import getConditionLink from 'utils/getConditionLink'

class ConditionDetails extends Component {
  static propTypes = {
    symptoms: PropTypes.array,
    probability: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    condition: PropTypes.object,
    conditionExplanation: PropTypes.object
  }

  isPresent(symptomId) {
    const { symptoms } = this.props
    return symptoms.find(s => s.id === symptomId).choiceId === 'present'
  }

  getProbabilityString() {
    const { probability } = this.props

    if (probability > 0.8) return 'Høj'
    if (probability > 0.5) return 'Mellem'
    return 'Lav'
  }

  getPrevalenceString() {
    const { prevalence } = this.props.condition

    switch (prevalence) {
      case 'very_rare':
        return 'Meget sjælden'
      case 'rare':
        return 'Sjælden'
      case 'moderate':
        return 'Moderat'
      case 'common':
        return 'Almindelig'
      default:
        return '...'
    }
  }

  getPrevalenceNumber() {
    const { prevalence } = this.props.condition

    switch (prevalence) {
      case 'very_rare':
        return 0.2
      case 'rare':
        return 0.3
      case 'moderate':
        return 0.6
      case 'common':
        return 0.9
      default:
        return 1
    }
  }

  render() {
    const {
      id,
      name,
      common_name,
      link,
      linkSource,
      condition,
      conditionExplanation: {
        supporting_evidence,
        conflicting_evidence,
        unconfirmed_evidence
      },
      probability
    } = this.props

    const conditionLink = link || getConditionLink(id)

    const searchLink = `https://www.sundhed.dk/borger/patienthaandbogen/soeg/?SearchTerm=${name}`

    if (!condition) {
      return (
        <Loading>
          <Spinner />
        </Loading>
      )
    }

    const { severity, prevalence } = condition

    return (
      <Wrapper severity={severity}>
        <Box prevalence={prevalence}>
          <BoxLabel>Hyppighed</BoxLabel>
          <Meter
            value={this.getPrevalenceNumber()}
            color={theme.colors.primary}
          />
          <MeterValue>{this.getPrevalenceString()}</MeterValue>
        </Box>
        <Box>
          <BoxLabel>Evidens</BoxLabel>
          <Meter value={probability} />
          <MeterValue>{this.getProbabilityString()}</MeterValue>
          <EvidenceSymptoms>
            <h3>Dette taler for {name}</h3>
            <div>
              {supporting_evidence &&
                supporting_evidence.map(e => (
                  <Symptom key={e.id} isPresent={this.isPresent(e.id)}>
                    {this.isPresent(e.id) ? <IconCheck /> : <IconMinus />}{' '}
                    {e.common_name}
                  </Symptom>
                ))}
            </div>
          </EvidenceSymptoms>
          {conflicting_evidence && conflicting_evidence.length > 0 && (
            <EvidenceSymptoms>
              <h3>Dette taler imod {name}</h3>
              <div>
                {conflicting_evidence.map(e => (
                  <Symptom isPresent={this.isPresent(e.id)} key={e.id}>
                    {this.isPresent(e.id) ? <IconCheck /> : <IconMinus />}{' '}
                    {e.common_name}
                  </Symptom>
                ))}
              </div>
            </EvidenceSymptoms>
          )}
          {unconfirmed_evidence && unconfirmed_evidence.length > 0 && (
            <EvidenceSymptoms>
              <h3>Følgende symptomer kan også tale for {name}</h3>
              <div>
                {unconfirmed_evidence.map(e => (
                  <Symptom key={e.id}>{e.common_name}</Symptom>
                ))}
              </div>
            </EvidenceSymptoms>
          )}
        </Box>
        <Link href={conditionLink || searchLink} target="_blank">
          <BoxLabel>{!linkSource && <IconSundhedDK />}</BoxLabel>
          <BoxValue>
            Læs om {common_name || name}
            {!linkSource ? ' på sundhed.dk' : ''}
          </BoxValue>
        </Link>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withConditionExplanation(),
  withCondition()
)(ConditionDetails)

const Box = styled.div`
  display: block;
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.tones.lightest};
  margin-bottom: 20px;
  color: inherit;
  max-width: 100%;
`

const Link = styled(Box).attrs({ as: 'a' })`
  display: block;
  border-top: 2px solid #b20c1c;

  svg {
    width: 80px;
    display: block;
  }
`

const Wrapper = styled.div`
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid ${props => props.theme.colors.tones.lightest};
`

const BoxLabel = styled(Text).attrs({
  size: 'xxs'
})`
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 10px;
`

const BoxValue = styled(Text).attrs({ size: 'xs' })``

const MeterValue = styled(Text).attrs({ size: 'xxs' })``

const Meter = styled.div`
  position: relative;
  height: 5px;
  width: 100%;
  background-color: ${props => props.theme.colors.tones.lightest};
  margin-bottom: 5px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: ${props => props.value * 100}%;
    background-color: ${props => props.color || props.theme.colors.secondary};
  }
`

const EvidenceSymptoms = styled.div`
  margin-top: 20px;
`

const Symptom = styled(Text).attrs({ size: 'xxs' })`
  display: flex;
  align-items: center;
  padding: 2px 0;

  svg {
    margin-right: 5px;
    width: 15px;
    height: auto;
  }
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`
