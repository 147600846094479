import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Route } from 'react-router'
import { compose } from 'redux'

import routes from 'routes'
import Page from 'ui/components/Page'
import AnimatedRoutes from 'ui/components/AnimatedRoutes'
import Loading from 'ui/components/generic/Loading'

import withCovid19 from 'containers/withCovid19'
import withTranslations from 'containers/withTranslations'

import Home from './Home'
import GenderSelect from './GenderSelect'
import AgeSelect from './AgeSelect'
import Interview from './Interview'
import Results from './Results'

class Covid19 extends Component {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    covid19Loading: PropTypes.bool,
    translationsLoading: PropTypes.bool
  }

  render() {
    const { location, match, covid19Loading, translationsLoading } = this.props

    const loading = covid19Loading || translationsLoading

    if (loading) {
      return <Loading />
    }

    return (
      <Wrapper>
        <AnimatedRoutes
          pathname={location.pathname.replace(match.url, '') || '/'}
          deepRoutes={[
            routes.patientInfo.genderSelect,
            routes.patientInfo.ageSelect
          ]}
        >
          <Switch location={location}>
            <Route exact path={match.url} render={renderPage(Home)} />
            <Route
              exact
              path={match.url + routes.patientInfo.genderSelect}
              render={renderPage(GenderSelect)}
            />
            <Route
              exact
              path={match.url + routes.patientInfo.ageSelect}
              render={renderPage(AgeSelect)}
            />
            <Route
              exact
              path={match.url + routes.interview}
              render={renderPage(Interview, 'interview')}
            ></Route>
            <Route
              path={match.url + routes.results}
              render={renderPage(Results)}
            />
          </Switch>
        </AnimatedRoutes>
      </Wrapper>
    )
  }
}

const renderPage = (Comp, key) => props => {
  const { url } = props.match // eslint-disable-line
  return (
    <Page key={key || url}>
      <Comp {...props} />
    </Page>
  )
}

export default compose(withCovid19(), withTranslations())(Covid19)

const Wrapper = styled.div``
