import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { reverse } from 'named-urls'

import routes from 'routes'
import { push } from 'utils/gtm'

import withSymptoms from 'containers/withSymptoms'
import withSubmitRating from 'containers/withSubmitRating'
import Button from 'ui/components/generic/Button'

class Actions extends Component {
  static propTypes = {
    symptoms: PropTypes.array
  }

  render() {
    const { symptoms, reset } = this.props

    return (
      <Wrapper>
        <Button
          to={reverse(routes.interview)}
          color="primary"
          onClick={() => push({ event: 'reviewAnswers', symptoms })}
        >
          Gennemse mine svar
        </Button>
        <Button onClick={reset} to={reverse(routes.symptoms)} color="primary">
          Start forfra
        </Button>
        <Button color="primary" as="a" onClick={() => window.print()}>
          Udskriv
        </Button>
      </Wrapper>
    )
  }
}

export default compose(withSubmitRating(), withSymptoms)(Actions)

const Wrapper = styled.div`
  margin-bottom: 30px;
`
