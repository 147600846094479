/* eslint-disable react/prop-types */
import React, { FC } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { reverse } from 'named-urls'

import routes from 'routes'
import { ReverseConfig } from './types'
import { StateType as PatientInfo } from 'redux/modules/patientInfo'
import { Symptom } from 'redux/modules/symptoms'
import useDiagnosis from 'containers/useDiagnosis'
import Text from 'ui/components/generic/Text'
import Loading from 'ui/components/generic/Loading'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Button from 'ui/components/generic/Button'

type Props = {
  config: ReverseConfig
  patientInfo: PatientInfo
  symptoms: Symptom[]
}

const Results: FC<Props> = ({ config, patientInfo, symptoms }) => {
  const { condition } = useParams<{ condition: string }>()

  const { loading, data: diagnosis } = useDiagnosis(patientInfo, symptoms)

  const match = diagnosis?.conditions?.find(
    (c: { id: string }) => c.id === config.conditionId
  )

  let probability = 'low'

  if (match) {
    probability = 'medium'
  }

  if (match?.probability >= config.probabilityThreshold) {
    probability = 'high'
  }

  return (
    <Wrapper>
      <PaddedContent large={false}>
        <Text size="lg" align="center" mb={30} weight="normal">
          Resultat{' '}
          {match ? `(Score: ${(match.probability * 100).toFixed(2)})` : ''}
        </Text>
        {loading && <Loading />}
        <MainContent>
          {!loading && (
            <>
              {probability === 'high' && (
                <Text
                  align="center"
                  dangerouslySetInnerHTML={{
                    __html: config.text.highProbability,
                  }}
                />
              )}
              {probability === 'medium' && (
                <Text
                  align="center"
                  dangerouslySetInnerHTML={{
                    __html: config.text.mediumProbability,
                  }}
                />
              )}
              {probability === 'low' && (
                <Text
                  align="center"
                  dangerouslySetInnerHTML={{
                    __html: config.text.lowProbability,
                  }}
                />
              )}
            </>
          )}
        </MainContent>
        <Actions>
          <Button
            color="primary"
            to={reverse(routes.reverse.toString(), { condition })}
          >
            Tag testen igen
          </Button>
        </Actions>
      </PaddedContent>
    </Wrapper>
  )
}

export default Results

const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.navHeight + 30}px;
  padding-bottom: ${(props) => props.theme.navHeight + 30}px;
  min-height: 100vh;
  display: flex;
  align-items: center;
`

const MainContent = styled.div`
  margin-bottom: 30px;
`

const Actions = styled.div`
  text-align: center;
`
