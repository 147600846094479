import React from 'react'

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="123"
    height="101"
    viewBox="0 0 123 101"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FEFEFE"
        d="M102.094 74.325l6.178-8.862 14.154-7.703L101.332 19l-16.15 8.789.88 1.615 13.554 24.907 5.238 9.625-5.777 8.286c-.316.454-.658.889-1.01 1.316l2.608 2.609c.5-.586.98-1.19 1.42-1.822zm1.648-20.124L90.17 29.261l9.69-5.274 17.578 32.301-9.69 5.273-4.006-7.36zM59.583 96.146a3.682 3.682 0 01-4.988-1.472 3.657 3.657 0 01-.297-2.799 3.657 3.657 0 011.77-2.189l11.305-6.152-1.758-3.23L47.85 89.97a3.68 3.68 0 01-4.987-1.47 3.682 3.682 0 011.472-4.99L62.1 73.844l-1.758-3.23-24.225 13.182a3.648 3.648 0 01-2.799.297 3.647 3.647 0 01-2.189-1.769 3.65 3.65 0 01-.297-2.798 3.652 3.652 0 011.77-2.189l6.413-3.49.047-.026 17.765-9.668-1.342-2.466-.642-.64-17.573 9.562a3.681 3.681 0 01-4.954-1.49 3.65 3.65 0 01-.297-2.799 3.654 3.654 0 011.77-2.189l7.273-3.959-1.757-3.23-7.275 3.96a7.308 7.308 0 00-3.538 4.377 7.306 7.306 0 00.594 5.597 7.303 7.303 0 002.625 2.76l-.867.471a7.306 7.306 0 00-3.539 4.378 7.306 7.306 0 00.594 5.597 7.306 7.306 0 004.378 3.54 7.309 7.309 0 005.598-.595l.867-.472a7.307 7.307 0 00.891 3.702c1.938 3.562 6.413 4.882 9.975 2.944l.863-.469a7.363 7.363 0 0010.871 6.644l20.614-11.218-2.712-2.71-19.66 10.698zM15.548 61.1L5.56 56.416l14.253-30.4-2.765-2.765L.67 58.184l14.59 6.84 6.698 8.476 2.885-2.28-6.262-7.925 12.292-26.22-2.765-2.766z"
      ></path>
      <path
        fill="#AEDEF8"
        d="M101.179 100.202L9.52 8.544 17.707.358l91.658 91.658z"
      ></path>
      <path
        fill="#FEFEFE"
        d="M80.894 42.997a3.653 3.653 0 011.897 2.08 3.647 3.647 0 01-.128 2.81 3.652 3.652 0 01-2.079 1.898 3.654 3.654 0 01-2.812-.129l-6.754-3.166 7.084 7.084a7.313 7.313 0 003.732-.33 7.302 7.302 0 004.158-3.796 7.301 7.301 0 00.257-5.623 7.306 7.306 0 00-3.794-4.158L55.54 27.05a23.95 23.95 0 00-5.824-1.86l6.011 6.01 25.167 11.798zm-37.667 6.71l-3.33-1.561-1.561 3.33 3.33 1.56a16.49 16.49 0 006.733 1.567l-4.68-4.68c-.164-.07-.33-.14-.492-.216"
      ></path>
    </g>
  </svg>
)
