import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

const withCondition = (
  getCondition = props => props.id,
  getAge = props => props.patientInfo.age,
  key = 'condition'
) => WrappedComponent =>
  class WithCondition extends Component {
    render() {
      return (
        <Query
          variables={{
            condition: getCondition(this.props),
            age: { value: getAge(this.props) }
          }}
          query={gql`
            query condition {
              condition(condition: $condition, age: $age)
                @rest(
                  type: "Condition"
                  path: "conditions/{args.condition}?age.value={args.age.value}"
                  method: "GET"
                ) {
                name
                common_name
                categories
                prevalence
                acuteness
                severity
                extras @type(name: "ConditionExtras") {
                  hint
                  icd10_code
                }
                triage_level
              }
            }
          `}
          ssr={false}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data[key]
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }

export default withCondition
