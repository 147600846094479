import React from 'react'

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="94"
    viewBox="0 0 80 94"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#AEDEF8"
        d="M68.813 40.102c-5.844 0-10.598-4.754-10.598-10.597 0-5.844 4.754-10.598 10.598-10.598 5.844 0 10.598 4.754 10.598 10.598 0 5.843-4.754 10.597-10.598 10.597m0-16.947a6.357 6.357 0 00-6.349 6.35c0 3.5 2.848 6.349 6.35 6.349a6.357 6.357 0 006.348-6.35 6.357 6.357 0 00-6.349-6.35m-7.087 48.135c-7.43 0-13.476-6.045-13.476-13.475s6.046-13.476 13.476-13.476c7.43 0 13.475 6.046 13.475 13.476 0 7.43-6.045 13.475-13.475 13.475m0-22.702c-5.088 0-9.227 4.139-9.227 9.227s4.14 9.227 9.227 9.227c5.088 0 9.227-4.14 9.227-9.227 0-5.088-4.139-9.227-9.227-9.227m-21.848-2.589c-5.839 0-10.59-4.75-10.59-10.59 0-5.839 4.751-10.589 10.59-10.589 5.84 0 10.59 4.75 10.59 10.59 0 5.838-4.75 10.59-10.59 10.59m0-16.932a6.348 6.348 0 00-6.34 6.341 6.348 6.348 0 006.34 6.342 6.348 6.348 0 006.341-6.342 6.348 6.348 0 00-6.34-6.34m4.347-10.756c-4.926 0-8.934-4.008-8.934-8.935 0-4.927 4.008-8.935 8.934-8.935 4.927 0 8.935 4.008 8.935 8.935 0 4.927-4.008 8.935-8.935 8.935m0-13.622a4.692 4.692 0 00-4.686 4.687 4.692 4.692 0 004.686 4.687 4.693 4.693 0 004.687-4.687 4.693 4.693 0 00-4.687-4.687"
      ></path>
      <path
        fill="#FEFEFE"
        d="M49.708 93.133c-8.367 0-16.734-3.185-23.103-9.554L3.937 60.91c-3.327-3.328-3.327-8.742 0-12.07a8.483 8.483 0 013.844-2.216l-5.022-5.022c-3.327-3.328-3.327-8.742 0-12.07a8.485 8.485 0 013.848-2.212l-1.065-1.065a8.479 8.479 0 01-2.5-6.035c0-2.28.888-4.422 2.5-6.034a8.475 8.475 0 016.034-2.5c2.279 0 4.422.888 6.034 2.5l7.619 7.619-3.004 3.004-7.62-7.62a4.29 4.29 0 00-6.06 0 4.292 4.292 0 000 6.062l13.008 13.007-3.004 3.004-6.725-6.725a4.29 4.29 0 00-6.061 0 4.29 4.29 0 000 6.06L30.715 63.55l-3.004 3.004L13 51.845a4.29 4.29 0 00-6.06 0 4.259 4.259 0 00-1.255 3.03c0 1.146.446 2.222 1.255 3.032l22.668 22.668c11.083 11.083 29.116 11.082 40.198 0l3.004 3.004c-6.37 6.369-14.736 9.554-23.103 9.554"
      ></path>
    </g>
  </svg>
)
