import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Switch, Route, withRouter } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import TagManager from 'react-gtm-module'

import routes from 'routes'
import withSiteMeta from 'containers/withSiteMeta'
import withTerms from 'containers/withTerms'
import withPatientInfo from 'containers/withPatientInfo'

import theme from 'ui/styles/theme'

import GlobalStyles from 'ui/styles/global'
import MediaProvider from 'redux/modules/media/MediaProvider'
import GridView from 'ui/components/generic/GridView'

import Navigation from 'ui/components/Navigation'
import BottomNavigation from 'ui/components/BottomNavigation'
import Overlay from 'ui/components/Overlay'
import Page from 'ui/components/Page'
import GlobalSeo from 'ui/components/GlobalSeo'
import ResetHandler from 'ui/components/ResetHandler'
import ContentPage from 'ui/components/ContentPage'
import Interview from 'ui/components/routes/Interview'
import Reverse from 'ui/components/routes/Reverse'
import LandingPage from 'ui/components/routes/LandingPage'
import Spinner from 'ui/components/generic/Spinner'
import Covid19 from 'ui/components/views/Covid19'
import List from 'ui/components/List'
import DiabetesRiskAssesment from 'ui/components/routes/DiabetesRiskAssesment'

class App extends Component {
  state = {
    loadedGTM: false,
  }

  componentDidMount() {
    this.initializeGTM()
  }

  componentDidUpdate(prevProps) {
    this.initializeGTM()
  }

  initializeGTM() {
    const { siteMeta, patientInfo } = this.props
    const { loadedGTM } = this.state

    if (
      process.env.NODE_ENV === 'production' &&
      !loadedGTM &&
      siteMeta &&
      siteMeta.gtmId &&
      patientInfo.acceptedTerms
    ) {
      this.setState({ loadedGTM: true }, () => {
        TagManager.initialize({ gtmId: siteMeta.gtmId })
      })
    }
  }

  render() {
    const { location, siteMetaLoading } = this.props

    return (
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={theme.grid}>
          <MediaProvider>
            <GlobalSeo />
            <BaseCSS />
            <ResetHandler />
            <GlobalStyles />
            {siteMetaLoading && (
              <Loading>
                <Spinner />
              </Loading>
            )}
            {!siteMetaLoading && (
              <Fragment>
                <Navigation />
                <Overlay />
                <Switch location={location}>
                  <Route path={routes.reverse.toString()}>
                    <Reverse />
                  </Route>
                  <Route path={routes.covid19} render={renderPage(Covid19)} />

                  <Route
                    path={routes.symptomList}
                    render={renderPage(List, 'list-symptoms', {
                      type: 'symptoms',
                    })}
                  />

                  <Route
                    path={routes.conditionList}
                    render={renderPage(List, 'list-conditions', {
                      type: 'conditions',
                    })}
                  />

                  <Route
                    path={routes.diabetesRiskAssesment}
                    component={DiabetesRiskAssesment}
                  />

                  <Route path="/" exact>
                    <LandingPage />
                  </Route>

                  <Route path={routes.interview.toString()}>
                    <Interview />
                  </Route>

                  <Route
                    exact
                    path={routes.contentPage}
                    render={renderPage(ContentPage)}
                  />
                </Switch>
              </Fragment>
            )}
            <BottomNavigation />
            {process.env.NODE_ENV === 'development' && <GridView />}
          </MediaProvider>
        </GridThemeProvider>
      </ThemeProvider>
    )
  }
}

App.propTypes = {
  siteMeta: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  siteMetaLoading: PropTypes.bool,
  patientInfo: PropTypes.shape({
    acceptedTerms: PropTypes.bool,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default compose(
  withRouter,
  withTerms(), // preload
  withPatientInfo,
  withSiteMeta()
)(App)

const renderPage = (Comp, key = null, compProps = {}) => (props) => {
  const { url } = props.match // eslint-disable-line
  return (
    <Page key={key || url}>
      <Comp {...compProps} {...props} />
    </Page>
  )
}

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`
