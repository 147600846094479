/* eslint-disable react/prop-types */
import React, { FC } from 'react'
import styled from 'styled-components'
import { reverse } from 'named-urls'

import routes from 'routes'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'

const FrontPage: FC = () => {
  return (
    <Wrapper>
      <PaddedContent large>
        <Content>
          <Text mb={30} size="lg" weight="normal">
            Type-2-Diabetes-test og -risikovurdering
          </Text>
          <Text size="sm" mb={30}>
            Tak fordi, du vil hjælpe os med denne test.
          </Text>
          <Button to={reverse(routes.diabetesRiskAssesment)}>
            Start risikovurdering
          </Button>
          <Button
            to={reverse(routes.reverse.frontPage, { condition: 'diabetes2' })}
          >
            Start test
          </Button>
        </Content>
      </PaddedContent>
    </Wrapper>
  )
}

export default FrontPage

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  text-align: center;
`
