import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'

import theme from 'ui/styles/theme'
import { reset } from 'redux/modules/symptoms'
import { showOverlay, hideOverlay, toggleOverlay } from 'redux/modules/overlay'
import routes from 'routes'
import withNav from 'containers/withNav'
import Link from 'ui/components/generic/FlexibleLink'
import Text from 'ui/components/generic/Text'
import Logo from 'ui/components/generic/Logo'
import Covid19Logo from 'ui/components/views/Covid19/Results/Covid19Logo'
import IconChevron from './generic/IconChevron'

class Navigation extends Component {
  state = { navVisible: false }

  componentDidUpdate(prevProps) {
    const { navVisible } = this.state

    if (
      navVisible &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({ navVisible: false })
    }
  }

  toggleNav = () => {
    const { overlayVisible, hideOverlay } = this.props

    // Handle scenario where menu icon is used to close overlay without nav items being visible (terms)
    if (overlayVisible) {
      hideOverlay()
    } else {
      this.setState(state => ({ navVisible: !state.navVisible }))
    }
  }

  handleHome = () => {
    const { reset, hideOverlay } = this.props
    reset()
    hideOverlay()
  }

  render() {
    const { overlayVisible, menuItems, menuItemsLoading, location } = this.props
    const { navVisible } = this.state

    const showCovid19Link =
      location.pathname === '/' || location.pathname === routes.results

    return (
      <Wrapper>
        <TopBar>
          <Container fluid>
            <Row alignItems="flex-start">
              <LogoCol xs={6} sm={2}>
                <Link href="/" onClick={this.handleHome}>
                  <Logo />
                </Link>
              </LogoCol>

              <NavCol xs={6} sm={10} fillHeight>
                {!menuItemsLoading && menuItems && (
                  <Links visible={navVisible}>
                    {menuItems.map(item => (
                      <Text
                        as={Link}
                        key={item.id}
                        href={item.url}
                        size="xs"
                        onClick={this.toggleNav}
                        target={item.target || undefined}
                      >
                        {item.label}
                      </Text>
                    ))}
                  </Links>
                )}
                <HelpIcon
                  onClick={this.toggleNav}
                  overlayVisible={navVisible || overlayVisible}
                >
                  <span>?</span>
                  <span>✕</span>
                </HelpIcon>
              </NavCol>
            </Row>
          </Container>

          {showCovid19Link && (
            <Covid19 href={routes.covid19}>
              <Covid19Logo color={theme.colors.secondary} />
              <Label>Screening</Label>
              <Arrow>
                <IconChevron color={theme.colors.secondary} />
              </Arrow>
              <Explanation>
                Klik for at prøve vores COVID-19 screening
              </Explanation>
            </Covid19>
          )}
        </TopBar>
      </Wrapper>
    )
  }
}

Navigation.propTypes = {
  overlayVisible: PropTypes.bool,
  overlayContent: PropTypes.string,
  showOverlay: PropTypes.func.isRequired,
  hideOverlay: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  menuItemsLoading: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      url: PropTypes.string
    })
  ),
  location: PropTypes.object.isRequired
}

export default compose(
  withRouter,
  withNav('MAIN_NAV'),
  connect(
    state => ({
      overlayVisible: state.overlay.visible
    }),
    { reset, showOverlay, hideOverlay, toggleOverlay }
  )
)(Navigation)

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
`

const TopBar = styled.div`
  position: relative;
  height: ${props => props.theme.navHeight}px;
  z-index: 2;
  border-top: 4px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.tones.lightest};

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.tones.lighter};
    left: ${props => props.theme.grid.container.padding}px;
    right: ${props => props.theme.grid.container.padding}px;
    opacity: 0.5;

    ${media.xs`
      left: ${props => props.theme.grid.container.paddingXs}px;
      right: ${props => props.theme.grid.container.paddingXs}px;
    `}
  }

  /** Make container, row fill height **/
  > div,
  > div > div {
    height: 100%;
  }
`

const NavCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${props => (props.fillHeight ? '100%' : 'auto')};
`

const LogoCol = styled(Col)`
  display: flex;
  align-items: center;
`

const HelpIcon = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  transform: rotate(${props => (props.overlayVisible ? 360 : 0)}deg);
  transition: 400ms;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    transition: all 400ms;
  }

  span:first-child {
    opacity: ${props => (props.overlayVisible ? 0 : 1)};
  }

  span:last-child {
    opacity: ${props => (props.overlayVisible ? 1 : 0)};
    bottom: 2px; /* tweak positioning */
  }
`

const Links = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: ${props => props.theme.navHeight - 4}px;
  right: 10px;
  width: calc(100vw - ${props => props.theme.grid.container.paddingXs * 2}px);
  text-align: center;
  background-color: ${props => props.theme.colors.tones.lightest};

  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateX(${props => (props.visible ? 0 : 20)}px);
  transition: 500ms;
  transition-delay: ${props => (props.visible ? 250 : 0)}ms;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  ${media.sm`
    display: block;
    height: auto;
    width: auto;
    position: relative;
    top: 0;
    margin-right: 30px;
    right: 0;
    text-align: right;
  `}

  a {
    display: inline-block;
    color: ${props => props.theme.colors.tones.dark};
    font-size: 13px;

    ${media.sm`
      font-size: 14px;
      margin-left: 20px;
    `}
  }
`

const entry = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`

const Explanation = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 100%;

  width: 200px;

  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 20px;

  font-size: 10px;
  color: ${props => props.theme.colors.tones.dark};

  opacity: 0;
  transform: translateX(-10px);
  transition: 500ms;
`

const Covid19 = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: ${props => props.theme.navHeight + 30}px;
  left: 0;
  background-color: #fff;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  padding: 15px 30px 15px 40px;

  animation: ${entry} 1000ms 2000ms both;

  svg {
    width: 100px;
  }

  ${media.xs`
    display: none;
  `}

  &:hover {
    ${Explanation} {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

const Label = styled.div`
  margin-top: -5px;
  font-size: 8px;
  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary};
`

const Arrow = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  margin-left: 15px;

  svg {
    width: 8px;
    height: auto;
  }
`
