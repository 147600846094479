import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Text from 'ui/components/generic/Text'

const Done = ({ onEnd }) => {
  return (
    <Content>
      <Avatar src="/images/oda.png" />
      <PaddedContent>
        <Section>Tak. Nu har jeg indsamlet de data, jeg skulle bruge.</Section>
        <Section>
          Følgende resultat udgør hverken en diagnose eller en komplet
          diagnose-screening. Servicen kender hverken alle kendte sygdomme eller
          symptomer og der kan således være forhold, som overses eller ikke
          screenes for.
        </Section>
        <Section>
          <strong>Du bør altid tale med en læge, hvis du føler dig syg.</strong>
        </Section>
        <Action>
          <Button onClick={onEnd} color="primary">
            Jeg er indforstået
          </Button>
        </Action>
      </PaddedContent>
    </Content>
  )
}

Done.propTypes = {
  onEnd: PropTypes.func
}

export default Done

const Content = styled.div`
  width: 100%;
  max-height: 100%;
  margin: auto;
  text-align: center;
  padding-bottom: 30px;
`

const Section = styled(Text).attrs({ as: 'p', mb: 20, size: 'sm' })``

const Action = styled.div`
  padding-top: 30px;
`

const Avatar = styled.img`
  position: relative;
  bottom: auto;
  margin: 20px auto;
  width: 100px;
  pointer-events: none;
  transition: 1000ms;

  ${media.md`
    width: 140px;
  `}

  ${media.lg`
    position: absolute;
    bottom: 0px;
    margin: 0;
    left: 20vw;
  `}
`
