import { v4 as uniqid } from 'uuid'
import {
  SET_AGE,
  SET_GENDER,
  ACCEPT_TERMS,
  RESET,
  IGNORE_EMERGENCY,
} from '../../actions'

const initialState = {
  age: null,
  gender: null,
  acceptedTerms: false,
  interviewId: uniqid(),
  ignoreEmergency: false,
}

const patientInfo = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SET_AGE:
      return {
        ...state,
        age: action.payload,
      }
    case SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      }
    case ACCEPT_TERMS:
      return {
        ...state,
        acceptedTerms: true,
      }
    case IGNORE_EMERGENCY:
      return {
        ...state,
        ignoreEmergency: true,
      }
    case RESET:
      return {
        ...initialState,
        acceptedTerms: state.acceptedTerms,
        interviewId: uniqid(),
      }
    default:
      return state
  }
}

export default patientInfo

export const setAge = (age: number | null) => ({ type: SET_AGE, payload: age })
export const setGender = (gender: StateType['gender']) => ({
  type: SET_GENDER,
  payload: gender,
})
export const acceptTerms = () => ({ type: ACCEPT_TERMS })
export const ignoreEmergency = () => ({ type: IGNORE_EMERGENCY })

export type StateType = {
  age: number | null
  gender: 'male' | 'female' | null
  acceptedTerms: boolean
  interviewId: string
  ignoreEmergency: boolean
}
