import React from 'react'
import withList from 'containers/withList'
import styled from 'styled-components'
import { reverse } from 'named-urls'
import { Link } from 'react-router-dom'

import routes from 'routes'

import Text from 'ui/components/generic/Text'
import Spinner from 'ui/components/generic/Spinner'
import PaddedContent from 'ui/components/generic/PaddedContent'

const List = props => {
  const { type, match, listLoading, list } = props

  const letter = match.params.letter || 'a'

  const letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'æ',
    'ø',
    'å'
  ]

  return (
    <Wrapper>
      <PaddedContent large>
        <Header>
          {type === 'conditions'
            ? 'Understøttede sygdomme'
            : 'Understøttede symptomer'}
        </Header>
        {type === 'conditions' && (
          <Text size="sm" mb={30}>
            <p>
              Følgende er en liste over sygdomme som indgår i Symptomtjekkers
              database. Symptomtjekker opdateres løbende med præcisioner, nye
              sygdomme, symptomer og spørgsmål. Der er således tale om en
              dynamisk database hvorfor nedenstående liste er vejledende. Der
              kan således optræde sygdomme i listen som endnu ikke er koblet med
              symptomer, spørgsmål eller sygdomme som kun er delvist
              understøttet og kun i særlige tilfælde vurderes af algoritmen. Du
              bør altid søge sundhedsfaglig hjælp, hvis du har det dårligt eller
              mistænker en særlig sygdom.
            </p>
          </Text>
        )}
        <Box>
          {letters.map(l => (
            <Letter selected={l === letter}>
              <Link
                to={reverse(
                  type === 'conditions'
                    ? routes.conditionList
                    : routes.symptomList,
                  { letter: l }
                )}
              >
                {l}
              </Link>
            </Letter>
          ))}
          {listLoading && <Spinner />}
        </Box>
        {list && (
          <Items>
            {list.map(item => (
              <Item key={item.id}>
                <strong>{item.commonName || item.name}</strong>
                {type === 'conditions' &&
                item.commonName &&
                item.name &&
                item.name !== item.commonName ? (
                  <span>&nbsp;({item.name})</span>
                ) : (
                  ''
                )}
              </Item>
            ))}
            {list.length === 0 && (
              <Item key="no-results">
                Ingen resultater under dette bogstav.
              </Item>
            )}
          </Items>
        )}
      </PaddedContent>
    </Wrapper>
  )
}

export default withList()(List)

const Wrapper = styled.div`
  padding: 100px 0;
`

const Header = styled.h1``

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 30px;

  &:after {
    content: '';
    flex: auto;
  }
`

const Letter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  background-color: #fff;
  margin-right: 1px;

  width: 30px;
  height: 30px;

  border-radius: 50%;

  background-color: ${props =>
    props.selected ? props.theme.colors.primary : 'transparent'};

  a {
    color: ${props => (props.selected ? '#fff' : props.theme.colors.primary)};

    transition: 500ms;
  }

  transition: 500ms;
`

const Items = styled.ul`
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  display: flex;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 4px;

  strong {
    &::first-letter {
      text-transform: uppercase;
    }
  }
`
