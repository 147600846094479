import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withScrollEntry from 'ui/styles/withScrollEntry'
import { SlideIn } from 'ui/styles/utils'
import Text from 'ui/components/generic/Text'
import Wrapper from 'ui/components/generic/PaddedContent'
import Links from 'ui/components/generic/Links'

export const query = `
  body
  buttons {
    text
    layout
    type
    externalLink
    internalLink {
      ...on Page {
        link
      }
    }
  }
`
class BodyText extends Component {
  static propTypes = {
    body: PropTypes.string,
    buttons: PropTypes.array,
    visible: PropTypes.bool
  }

  render() {
    const { body, buttons, visible } = this.props

    return (
      <Wrapper>
        <SlideIn visible={visible}>
          <Text dangerouslySetInnerHTML={{ __html: body }} size="xs" />
        </SlideIn>
        <SlideIn visible={visible} delay={200}>
          <Links color="primary" links={buttons} />
        </SlideIn>
      </Wrapper>
    )
  }
}

export default withScrollEntry()(BodyText)
