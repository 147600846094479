import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { push } from 'utils/gtm'
import theme from 'ui/styles/theme'
import Text from 'ui/components/generic/Text'
import IconChevron from 'ui/components/generic/IconChevron'
import ConditionDetails from './ConditionDetails'

class Conditions extends Component {
  static propTypes = {
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        common_name: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.string
      })
    ),
    header: PropTypes.node
  }

  state = { expanded: [] }

  toggle(id) {
    this.setState(state => ({
      expanded: state.expanded.includes(id)
        ? state.expanded.filter(e => e !== id)
        : state.expanded.concat(id)
    }))

    // tracking
    if (!this.state.expanded.includes(id)) {
      push({
        event: 'expandCondition',
        value: this.props.conditions.find(c => c.id === id)
      })
    }
  }

  getConditionTitle(c) {
    const name = c.name
    const commonName = c.common_name
    if (!c.description) {
      return name
    }

    return `${commonName} ${name !== commonName ? `(${name})` : ''}`
  }

  getConditionDescription(c) {
    if (!c.description) {
      if (c.common_name === c.name) {
        return null
      }
      return c.common_name
    }
    return c.description
  }

  render() {
    const { conditions, header } = this.props
    const { expanded } = this.state

    if (conditions.length === 0) return null

    return (
      <Wrapper>
        <Header>{header}</Header>
        {conditions.map(condition => (
          <Condition
            key={condition.id}
            expanded={expanded.includes(condition.id)}
          >
            <ConditionHeader onClick={() => this.toggle(condition.id)}>
              <Title>
                {this.getConditionTitle(condition)}
                <ExpandIndicator>
                  <Text size="xxs" as="span">
                    {expanded.includes(condition.id) ? 'Luk' : 'Læs mere'}
                  </Text>
                  <IconChevron
                    direction={expanded.includes(condition.id) ? 'up' : 'down'}
                    color={theme.colors.primary}
                  />
                </ExpandIndicator>
              </Title>
              {this.getConditionDescription(condition) && (
                <Description>
                  {this.getConditionDescription(condition)}
                </Description>
              )}
            </ConditionHeader>
            <Details expanded={expanded.includes(condition.id)}>
              {expanded.includes(condition.id) && (
                <ConditionDetails key={condition.id} {...condition} />
              )}
            </Details>
          </Condition>
        ))}
      </Wrapper>
    )
  }
}

export default Conditions

const Wrapper = styled.div`
  margin-bottom: 30px;
`
const Condition = styled.div`
  margin-top: 1px;
  background-color: #fff;
  padding: 20px;
`
const Header = styled.div`
  padding: 20px;
  background-color: #fff;
`

const ConditionHeader = styled.div`
  cursor: pointer;
`

const Title = styled(Text).attrs({ size: 'sm', as: 'h3' })`
  position: relative;
  padding-right: 110px;
`

const Description = styled(Text).attrs({ size: 'xs' })`
  padding-right: 20px;
`

const Details = styled.div`
  min-height: ${props => (props.expanded ? 400 : 0)}px;
  transition: 500ms;
`

const ExpandIndicator = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;

  span {
    display: block;
    text-transform: uppercase;
    margin-right: 15px;
  }
`
