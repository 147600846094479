import React from 'react'
import { reverse } from 'named-urls'

import routes from 'routes'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'

export default () => (
  <PaddedContent>
    <h1>Diabetes-2 rissikovurdering</h1>
    <Text as="p">
      Her kan du teste, hvor disponeret du er for at have eller få
      type-2-diabetes.
    </Text>
    <Button to={reverse(routes.diabetesRiskAssesment, { step: 1 })}>
      Start
    </Button>
  </PaddedContent>
)
