import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'

import withSuggestSymptomMutation from 'containers/withSuggestSymptomMutation'
import withOverlay from 'containers/withOverlay'

import responsiveSpacing from 'ui/styles/responsiveSpacing'
import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Spinner from 'ui/components/generic/Spinner'

class SuggestSymptom extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.search,
      comment: ''
    }
  }

  submit = () => {
    const { suggestSymptom } = this.props
    const { title, comment } = this.state
    console.log('submit', title, comment)
    if (title) {
      suggestSymptom({
        variables: {
          title,
          comment
        }
      })
    }
  }

  render() {
    const { title, comment } = this.state
    const {
      suggestedSymptomLoading,
      suggestedSymptom,
      hideOverlay
    } = this.props

    return (
      <Wrapper>
        <Content>
          <PaddedContent>
            <SlideInOutGroup>
              {!suggestedSymptomLoading && !suggestedSymptom && (
                <SlideInOut key="form" direction="right">
                  <Header>Foreslå symptom</Header>
                  <p>
                    Vi arbejder konstant på at udvikle og forbedre servicen, og
                    sætter stor pris på alle bidrag fra vores brugere
                  </p>
                  <p>
                    Udfyld felterne nedenfor, for at foreslå det symptom, du
                    savner i søgningen.
                  </p>
                  <Form>
                    <Field>
                      <Label>Dit symptom</Label>
                      <input
                        type="text"
                        placeholder="Skriv det ønskede symptom her"
                        onChange={e => this.setState({ title: e.target.value })}
                        value={title}
                      />
                    </Field>
                    <Field>
                      <Label>Din kommentar</Label>
                      <textarea
                        placeholder="Uddyb gerne din forespørgsel her"
                        onChange={e =>
                          this.setState({ comment: e.target.value })
                        }
                        value={comment}
                      />
                    </Field>
                    <Button color="primary" onClick={this.submit}>
                      Foreslå symptom
                    </Button>
                  </Form>
                </SlideInOut>
              )}
              {suggestedSymptomLoading && (
                <SpinnerWrapper key="loading" direction="right">
                  <Spinner />
                </SpinnerWrapper>
              )}
              {suggestedSymptom && (
                <SlideInOut key="success" direction="right">
                  <Header>Tak for hjælpen</Header>
                  <p>
                    Mange tak for dit bidrag til at gøre vores service bedre.
                  </p>
                  <p>Vh. Spørg Oda</p>
                  <Button color="secondary" onClick={hideOverlay}>
                    Afslut
                  </Button>
                </SlideInOut>
              )}
            </SlideInOutGroup>
          </PaddedContent>
        </Content>
      </Wrapper>
    )
  }
}

SuggestSymptom.propTypes = {
  search: PropTypes.string
}

export default compose(
  withOverlay,
  withSuggestSymptomMutation()
)(SuggestSymptom)

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  padding-top: ${props => props.theme.navHeight}px;
`

const SpinnerWrapper = styled(SlideInOut)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  ${responsiveSpacing(0.5, 'padding-top')}
  ${responsiveSpacing(0.5, 'padding-bottom')}
`

const Header = styled(Text).attrs({ as: 'h1' })``

const Form = styled.div``

const Field = styled.div`
  margin: 20px 0;
`

const Label = styled(Text).attrs({ size: 'xs' })`
  margin-bottom: 10px;
  font-weight: ${props => props.theme.fontWeights.bold};
`
