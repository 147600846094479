import { SHOW_OVERLAY, HIDE_OVERLAY } from 'redux/actions'

const defaultState = {
  visible: false,
  content: null,
  data: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_OVERLAY:
      return {
        ...state,
        visible: true,
        content: action.payload.key || state.content,
        data: action.payload.data || state.data
      }
    case HIDE_OVERLAY:
      return {
        ...state,
        visible: false
      }
    default:
      return state
  }
}

export const showOverlay = (key, data = null) => ({
  type: SHOW_OVERLAY,
  payload: {
    key,
    data
  }
})

export const hideOverlay = () => ({
  type: HIDE_OVERLAY
})

export const toggleOverlay = () => (dispatch, getState) => {
  if (getState().overlay.visible) {
    dispatch(showOverlay())
  } else {
    dispatch(hideOverlay())
  }
}
