import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { push } from 'utils/gtm'

import getMatchingScreenings from 'utils/getMatchingScreenings'
import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'
import withCustomScreenings from 'containers/withCustomScreenings'

import Text from 'ui/components/generic/Text'

class Cancer extends Component {
  static propTypes = {
    symptoms: PropTypes.array,
    customScreenings: PropTypes.object
  }

  trackOutboundClick(e) {
    push({ event: 'clickConditionExternalLink', value: e.target.href })
  }

  render() {
    const { customScreenings, symptoms } = this.props

    if (!customScreenings || !customScreenings.cancer) return null

    const matchingScreenings = getMatchingScreenings(
      customScreenings.cancer.screenings,
      symptoms
    )

    if (matchingScreenings.length === 0) return null

    return (
      <Wrapper>
        <Header>
          <Text>De 7 tegn</Text>
          <MainLogo src="/images/de7tegn.png" alt="De 7 tegn" />
        </Header>
        <Content>
          {matchingScreenings.map(({ screening }) => (
            <Screening>
              <ScreeningHeader>
                <Row>
                  <Col sm={3}>
                    <img
                      src={screening.image.sourceUrl}
                      alt={screening.title}
                    />
                  </Col>
                  <Col sm={9}>
                    <h2>{screening.title}</h2>
                    <Text
                      size="xs"
                      dangerouslySetInnerHTML={{ __html: screening.text }}
                      mb={20}
                    />
                    {screening.url && (
                      <Text size="xs">
                        <a href={screening.url} target="_blank">
                          Læs mere om {screening.title}
                        </a>
                      </Text>
                    )}
                  </Col>
                </Row>
              </ScreeningHeader>
            </Screening>
          ))}

          {/* {symptoms.length > 0 && (
              <React.Fragment>
                <p>Du har angivet symptomer, som kan falde i denne kategori:</p>
                <Symptoms>
                  {symptoms.map(symptom => (
                    <Symptom key={symptom.id}>
                      {symptom.name.charAt(0).toUpperCase() +
                        symptom.name.slice(1)}
                    </Symptom>
                  ))}
                </Symptoms>
              </React.Fragment>
            )} */}
        </Content>
        <Footer>
          <a href="https://www.cancer.dk/de7tegn/">Læs om De 7 tegn →</a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="22"
            viewBox="0 0 41 22"
          >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g fill="#ED1C24">
                <path d="M30 0c-3.1 0-5.8 1.2-7.8 3.2l-1.6 1.6L19 3.2c-2-2-4.8-3.2-7.8-3.2C5.1 0 .2 4.9.2 11c0 6 4.9 11 11 11 3.1 0 5.8-1.3 7.8-3.3l1.6-1.5 1.5 1.5c2.1 2 4.8 3.3 7.9 3.3 6 0 11-4.9 11-11S36.1 0 30 0M16.9 16.6c-1.5 1.5-3.5 2.3-5.7 2.3-4.4 0-8-3.5-8-7.9s3.6-8 8-8c2.2 0 4.2.9 5.6 2.3l1.6 1.6-1.9 1.9L15 7.2c-1-.9-2.3-1.5-3.8-1.5-2.9 0-5.3 2.4-5.3 5.3s2.3 5.3 5.3 5.3c1.5 0 2.8-.6 3.8-1.6l1.6-1.5 1.9 1.9-1.6 1.5zm-4.1-4c-.4.4-.9.7-1.6.7-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2.6 0 1.2.2 1.6.6l1.6 1.6-1.6 1.5zm11.9.6l1.6 1.5c.9 1 2.3 1.6 3.8 1.6 2.9 0 5.2-2.3 5.2-5.3 0-2.9-2.3-5.3-5.2-5.3-1.5 0-2.8.6-3.8 1.5l-1.6 1.6-1.9-1.9 1.6-1.6C25.9 3.9 27.8 3 30 3c4.4 0 7.9 3.6 7.9 8s-3.5 7.9-7.9 7.9c-2.2 0-4.2-.8-5.7-2.3l-1.5-1.5 1.9-1.9zm-4.1-.3L18.7 11l1.9-1.9 1.9 1.9-1.9 1.9zm6.3-1.9l1.6-1.6c.4-.3.9-.6 1.6-.6 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-.6 0-1.2-.3-1.6-.7L26.9 11z"></path>
              </g>
            </g>
          </svg>
        </Footer>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withCustomScreenings()
)(Cancer)

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.tones.lightest};
  padding: 20px;
`

const Content = styled.div`
  padding: 30px;
`

const MainLogo = styled.img`
  width: 30px;
  height: auto;
`

const Footer = styled(Text).attrs({ size: 'xs' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ce2127;
  padding: 30px;

  &,
  a,
  svg path {
    fill: #fff;
    color: #fff;
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`

const Screening = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const ScreeningHeader = styled.div`
  img {
    max-width: 100%;
  }
`
