import React from 'react'
import styled from 'styled-components'

export default props => <Wrapper {...props} />

const Wrapper = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px dashed ${props => props.theme.colors.primary};
  margin-top: -12.5px;
  background-color: ${props =>
    props.checked ? props.theme.colors.primary : 'transparent'};
`
