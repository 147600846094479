import { include } from 'named-urls'

export default {
  covid19: '/covid19',

  interview: include('/', {
    symptoms: '/',
    genderSelect: 'info/koen',
    ageSelect: 'info/alder',
    riskFactors: 'risikofaktorer',
    related: 'relaterede/:symptomId?',
    interview: 'interview/:questionIndex?',
    results: 'resultater',
  }),

  reverse: include('/opslag/:condition', {
    frontPage: '',
    genderSelect: 'info/koen',
    ageSelect: 'info/alder',
    interview: 'interview/:questionIndex?',
    results: 'resultater',
  }),

  conditionList: '/sygdomme/:letter?',

  symptomList: '/symptomer/:letter?',

  diabetesRiskAssesment: '/diabetes-2-risikovurdering/:step?',

  contentPage: '/:slug'
}
