import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default (
  getCondition = props => props.id,
  getAge = props => props.patientInfo.age,
  getGender = props => props.patientInfo.gender,
  getSymptoms = props => props.symptoms,
  key = 'conditionExplanation'
) => WrappedComponent =>
  class WithConditionExplanation extends Component {
    render() {
      return (
        <Query
          variables={{
            input: {
              age: { value: getAge(this.props) },
              sex: getGender(this.props),
              target: getCondition(this.props),
              evidence: getSymptoms(this.props).map(symptom => ({
                id: symptom.id,
                choice_id: symptom.choiceId,
                initial: symptom.initial
              }))
            }
          }}
          query={gql`
            query conditionExplanation {
              conditionExplanation(input: $input)
                @rest(
                  type: "ConditionExplanation"
                  path: "explain/"
                  method: "POST"
                ) {
                supporting_evidence @type(name: "ExplanationItem") {
                  id
                  name
                  common_name
                }
                conflicting_evidence @type(name: "ExplanationItem") {
                  id
                  name
                  common_name
                }
                unconfirmed_evidence @type(name: "ExplanationItem") {
                  id
                  name
                  common_name
                }
              }
            }
          `}
          ssr={false}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data[key]
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
