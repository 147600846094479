import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Typed from 'react-typed'
import posed from 'react-pose'
import { connect } from 'react-redux'

import { push } from 'utils/gtm'
import { showOverlay } from 'redux/modules/overlay'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import IconSearch from 'ui/components/generic/IconSearch'
import { SlideInOutGroup, transition } from 'ui/styles/poses'
import SearchResults from './SearchResults'

class SearchInput extends Component {
  constructor(props) {
    super(props)

    this.searchInput = React.createRef()
    this.wrapper = React.createRef()

    this.state = { searchFocused: false, value: '', search: '' }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.symptoms.length !== this.props.symptoms.length) {
      this.setState({ value: '', search: '' })
    }
    if (nextState.search !== this.state.search) {
      push({ event: 'searchSymptom', value: nextState.search })
      this.props.onSearch(nextState.search)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  search = () => {
    this.setState(state => ({ search: state.value }))
  }

  handleInputChange = e => {
    const { acceptedTerms, autoSearch } = this.props
    if (acceptedTerms) {
      this.setState({ value: e.target.value })
      clearTimeout(this.debounce)
      if (autoSearch) {
        this.debounce = setTimeout(this.search, 500)
      }
    }
  }

  handleFocus = () => {
    this.setState({ searchFocused: true })
    this.typed.stop()
    this.typed.reset()
  }

  showTerms = () => this.props.showOverlay('terms')

  handleBlur = () => {
    this.setState({ searchFocused: false })
    this.typed.start()
  }

  handleKeyDown = e => {
    const { overlayVisible } = this.props
    // focus search input when typing letters or numbers when not already focused
    if (
      /^[a-z0-9]$/i.test(e.key) &&
      document.activeElement !== this.searchInput.current &&
      !overlayVisible
    ) {
      this.searchInput.current.focus()
    }
  }

  handleReturnKey = e => {
    if (e.key === 'Enter') {
      this.search()
      this.searchInput.current.blur()
    }
  }

  showTerms = () => {
    this.props.showOverlay('terms')
  }

  render() {
    const { symptoms, acceptedTerms, autoSearch } = this.props
    const { searchFocused, value, search } = this.state

    const showAssistant =
      (!searchFocused && !value && !search && symptoms.length === 0) ||
      !acceptedTerms

    return (
      <Search ref={this.wrapper}>
        <InputWrapper>
          <Assistant visible={showAssistant}>
            <Typed
              strings={[
                '^2500Hovedpine',
                '^500Vægttab',
                '^500Ondt i halsen',
                '^500Svimmelhed',
                '^500Hjertebanken',
                '^500Udslæt'
              ]}
              typeSpeed={30}
              backSpeed={40}
              backDelay={1500}
              smartBackspace
              showCursor
              loop
              typedRef={typed => {
                this.typed = typed
              }}
            >
              <span />
            </Typed>
          </Assistant>
          <Input
            onChange={this.handleInputChange}
            onKeyPress={this.handleReturnKey}
            value={value}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            placeholder={showAssistant ? undefined : 'Skriv symptom..'}
            ref={this.searchInput}
          />
          {!autoSearch && (
            <SearchButton color="primary" round onClick={this.search}>
              <IconSearch />
            </SearchButton>
          )}
          {!acceptedTerms && <InputMask onClick={this.showTerms} />}
        </InputWrapper>
        <SlideInOutGroup withParent={false}>
          {search && search.length > 2 && (
            <ResultsPose key="searchResults">
              <SearchResults search={search} />
            </ResultsPose>
          )}
        </SlideInOutGroup>
      </Search>
    )
  }
}

SearchInput.propTypes = {
  symptoms: PropTypes.array,
  acceptedTerms: PropTypes.bool,
  showOverlay: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  autoSearch: PropTypes.bool
}

export default connect(
  state => ({
    acceptedTerms: state.patientInfo.acceptedTerms,
    overlayVisible: state.overlay.visible
  }),
  { showOverlay }
)(SearchInput)

const ResultsPose = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition
  },
  exit: {
    opacity: 0,
    y: '-50px',
    transition
  },
  pre: {
    opacity: 0,
    y: '-50px',
    transition
  },
  flip: transition
})

const Search = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 40px;
  width: 450px;
  max-width: 100%;
`

const Input = styled(Text).attrs({
  as: 'input',
  size: 'md'
})`
  text-align: center;
  width: 100%;
  height: 60px;
  padding: 20px;
  background-color: transparent;
  border-bottom: 2px solid ${props => props.theme.colors.primary};
  border-radius: 0;
  font-weight: 100;
  position: relative;
  line-height: 1;

  ${media.xs`
    text-align: left;
    padding: 20px 40px 20px 0px;
  `}
`

const InputWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.tones.lightest};
  z-index: 2;
  text-align: center;

  ${media.xs`
    text-align: left;
  `}
`

const SearchButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
`

const InputMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: text;
`

const Assistant = styled(Text).attrs({
  size: 'md'
})`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 20px;
  pointer-events: none;
  line-height: 1;

  opacity: ${props => (props.visible ? 1 : 0)};

  ${media.xs`
    padding: 20px 40px 20px 0px;

    span {
      padding: 0;
    }
  `}
`
