import React from 'react'
import PropTypes from 'prop-types'
import theme from 'ui/styles/theme'

const IconCheck = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
      <path
        fill={color || theme.colors.secondary}
        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M17.7 9.607l-6.638 6.117a1.06 1.06 0 01-.721.276c-.262 0-.523-.092-.722-.276l-3.32-3.059a.889.889 0 010-1.33 1.082 1.082 0 011.445 0l2.597 2.393 5.916-5.452a1.082 1.082 0 011.444 0 .889.889 0 010 1.33z"
      ></path>
    </g>
  </svg>
)

IconCheck.propTypes = {
  color: PropTypes.string
}

export default IconCheck
