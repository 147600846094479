import { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export default (size = 'md') => css`
  /**
  * Font size
  **/
  font-size: ${props => props.theme.fontSizes[size].xs.fs}px;
  line-height: ${props => props.theme.fontSizes[size].xs.lh}px;
  letter-spacing: ${props => props.theme.fontSizes[size].xs.ls}px;

  ${media.sm`
    font-size: ${props => props.theme.fontSizes[size].sm.fs}px;
    line-height: ${props => props.theme.fontSizes[size].sm.lh}px;
    letter-spacing: ${props => props.theme.fontSizes[size].sm.ls}px;
  `}

  ${media.md`
    font-size: ${props => props.theme.fontSizes[size].md.fs}px;
    line-height: ${props => props.theme.fontSizes[size].md.lh}px;
    letter-spacing: ${props => props.theme.fontSizes[size].md.ls}px;
  `}

  ${media.lg`
    font-size: ${props => props.theme.fontSizes[size].lg.fs}px;
    line-height: ${props => props.theme.fontSizes[size].lg.lh}px;
    letter-spacing: ${props => props.theme.fontSizes[size].lg.ls}px;
  `}

   ${media.xl`
    font-size: ${props => props.theme.fontSizes[size].xl.fs}px;
    line-height: ${props => props.theme.fontSizes[size].xl.lh}px;
    letter-spacing: ${props => props.theme.fontSizes[size].xl.ls}px;
  `}
`
