import React, { useCallback } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reverse } from 'named-urls'

import routes from 'routes'
import { setAge, setGender } from 'redux/modules/patientInfo'

import AnimatedRoutes from 'ui/components/AnimatedRoutes'
import Page from 'ui/components/Page'
import GenderSelect from 'ui/components/views/GenderSelect'
import AgeSelect from 'ui/components/views/AgeSelect'
import Symptoms from './Symptoms'
import RelatedSymptoms from './RelatedSymptoms'
import RiskFactors from './RiskFactors'
import Questions from './Questions'
import Results from './Results'

const Interview = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const patientInfo = useSelector((state) => state.patientInfo)

  const next = (current) => {
    const routeNames = Object.keys(routes.interview)
    const index = routeNames.findIndex((route) => route === current)
    const nextRoute = routeNames[index + 1]

    console.log(routes)

    if (nextRoute) {
      push(reverse(routes.interview[nextRoute]))
    }
  }

  return (
    <AnimatedRoutes
      pathname={location.pathname}
      deepRoutes={['/info/koen', '/info/alder']}
    >
      <Switch location={location}>
        <Route exact path={routes.interview.symptoms}>
          <Page key="symptoms">
            <Symptoms onContinue={() => next('symptoms')} />
          </Page>
        </Route>
        <Route exact path={routes.interview.genderSelect}>
          <Page key="genderSelect">
            <GenderSelect
              gender={patientInfo.gender}
              onChange={(gender) => {
                dispatch(setGender(gender))
                next('genderSelect')
              }}
            />
          </Page>
        </Route>
        <Route exact path={routes.interview.ageSelect}>
          <Page key="ageSelect">
            <AgeSelect
              onChange={(age) => {
                dispatch(setAge(age))
                next('ageSelect')
              }}
            />
          </Page>
        </Route>
        {process.env.REACT_APP_ENABLE_RISK_FACTORS && (
          <Route exact path={routes.interview.riskFactors}>
            <Page key="riskFactors">
              <RiskFactors onContinue={() => next('riskFactors')} />
            </Page>
          </Route>
        )}
        <Route exact path={routes.interview.related}>
          <Page key="related">
            <RelatedSymptoms onContinue={() => next('related')} />
          </Page>
        </Route>
        <Route exact path={routes.interview.interview}>
          <Page key="interview">
            <Questions onContinue={() => next('interview')} />
          </Page>
        </Route>
        <Route path={routes.interview.results}>
          <Page key="results">
            <Results />
          </Page>
        </Route>
      </Switch>
    </AnimatedRoutes>
  )
}

export default Interview
