import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import withGlobalSeo from 'containers/withGlobalSeo'

class GlobalSeo extends Component {
  render() {
    const { globalSeo } = this.props

    if (!globalSeo) return null

    const {
      title,
      metaDescription,
      facebookTitle,
      facebookDescription,
      facebookImage
    } = globalSeo

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          <meta property="description" content={metaDescription} />
          <meta property="og:title" content={facebookTitle} />
          <meta property="og:description" content={facebookDescription} />
        </Helmet>
        {facebookImage && (
          <Helmet>
            <meta property="og:image" content={facebookImage.sourceUrl} />
            <meta
              property="og:image:width"
              content={facebookImage.mediaDetails.width}
            />
            <meta
              property="og:image:height"
              content={facebookImage.mediaDetails.height}
            />
          </Helmet>
        )}
      </Fragment>
    )
  }
}

GlobalSeo.propTypes = {
  globalSeo: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      faceookTitle: PropTypes.string,
      facebookDescription: PropTypes.string,
      facebookImage: PropTypes.shape({
        sourceUrl: PropTypes.string,
        mediaDetails: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number
        })
      })
    }),
    PropTypes.bool
  ])
}

export default withGlobalSeo()(GlobalSeo)
