import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithCustomScreenings extends Component {
    render() {
      return (
        <Query
          query={gql`
            query CustomScreenings {
              optionsCustomScreenings {
                cancer {
                  screenings {
                    title
                    text
                    url
                    image {
                      sourceUrl
                    }
                    rules {
                      symptoms {
                        symptom
                        choiceId
                      }
                    }
                  }
                }
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  customScreeningsLoading={loading}
                  customScreenings={
                    !loading && !error && data.optionsCustomScreenings
                  }
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
