import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default (
  getType = props => props.type,
  getLetter = props => props.match.params.letter,
  key = 'list'
) => WrappedComponent =>
  class WithList extends Component {
    render() {
      const type = getType(this.props)
      const letter = getLetter(this.props) || 'a'

      return (
        <Query
          query={type === 'conditions' ? conditionQuery : symptomQuery}
          variables={{ letter: letter }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data.list
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }

const symptomQuery = gql`
  query symptomList($letter: String!) {
    list(letter: $letter)
      @rest(type: "SymptomListItem", path: "list/symptoms/{args.letter}") {
      id
      name
      commonName
    }
  }
`

const conditionQuery = gql`
  query conditionList($letter: String!) {
    list(letter: $letter)
      @rest(type: "ConditionListItem", path: "list/conditions/{args.letter}") {
      id
      name
      commonName
    }
  }
`
