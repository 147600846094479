import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithTranslations extends Component {
    render() {
      return (
        <Query
          query={gql`
            query Translations {
              optionsTranslations {
                symptoms {
                  id
                  translation {
                    commonName
                    name
                    question
                  }
                }
                riskFactors {
                  id
                  translation {
                    commonName
                    name
                    question
                  }
                }
                strings {
                  source
                  translation
                }
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  translationsLoading={loading}
                  translations={!loading && !error && data.optionsTranslations}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
