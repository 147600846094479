import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default (
  getAge = props => props.patientInfo.age,
  getGender = props => props.patientInfo.gender,
  getSymptoms = props => props.symptoms,
  key = 'suggestedSymptoms'
) => WrappedComponent =>
  class WithSuggestedSymptoms extends Component {
    render() {
      return (
        <Query
          variables={{
            input: {
              age: { value: getAge(this.props) },
              sex: getGender(this.props),
              evidence: getSymptoms(this.props)
                .filter(
                  s => s.source === 'initial' || s.source === 'predefined'
                )
                .map(symptom => ({
                  id: symptom.id,
                  choice_id: 'present',
                  source: symptom.source
                }))
            }
          }}
          ssr={false}
          query={gql`
            query suggestedSymptoms {
              suggestedSymptoms(input: $input)
                @rest(
                  type: "SuggestedSymptom"
                  path: "suggest/"
                  method: "POST"
                ) {
                id
                name
                common_name
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data[key]
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
