import React from 'react'
import styled from 'styled-components'
import { Row, Col, media } from 'styled-bootstrap-grid'

import Text from 'ui/components/generic/Text'

import AvoidHandshakeLogo from './AvoidHandshakeLogo'
import SSTLogo from './SSTLogo'
import CleanLogo from './CleanLogo'
import Covid19Logo from './Covid19Logo'
import KeepDistanceLogo from './KeepDistanceLogo'
import SneezeSleeveLogo from './SneezeSleeveLogo'
import WashHandsLogo from './WashHandsLogo'

const advice = [
  {
    icon: <WashHandsLogo />,
    text: 'Vask dine hænder tit eller brug håndsprit'
  },
  {
    icon: <SneezeSleeveLogo />,
    text: 'Host eller nys i dit ærme - ikke i dine hænder'
  },
  {
    icon: <AvoidHandshakeLogo />,
    text: 'Undgå håndtryk, kindkys og kram  - begræns den fysiske aktivitet'
  },
  {
    icon: <CleanLogo />,
    text: 'Vær opmærksom på rengøring - både hjemme og på arbejdspladsen'
  },
  {
    icon: <KeepDistanceLogo />,
    text: 'Hold afstand - bed andre tage hensyn'
  }
]

export default () => {
  return (
    <Wrapper>
      <Logos>
        <SSTLogo />
        <Covid19Logo />
      </Logos>
      <Header>
        Ny coronavirus
        <br />
        Beskyt dig selv og andre
      </Header>
      {advice.map(a => (
        <Advice>
          <Row alignItems="center">
            <Col justifyContent="center" md={4} xl={3}>
              {a.icon}
            </Col>
            <Col md={8} xl={9}>
              <AdviceText dangerouslySetInnerHTML={{ __html: a.text }} />
            </Col>
          </Row>
        </Advice>
      ))}
      <Footer>
        Hold dig altid opdateret på{' '}
        <a href="http://coronasmitte.dk">coronasmitte.dk</a>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.sst};
  padding: 30px;
  color: #fff;
`

const Header = styled(Text).attrs({ as: 'h1' })``

const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  svg {
    max-width: 100px;
    height: auto;
  }
`

const Advice = styled.div`
  margin-bottom: 10px;

  svg {
    transform: scale(0.5);
    margin: auto;
  }
`

const AdviceText = styled(Text).attrs({ size: 'sm' })`
  text-align: center;

  ${media.md`
    text-align: left;
    max-width: 300px;
  `}
`

const Footer = styled(Text).attrs({ size: 'sm' })`
  margin-top: 50px;
  text-align: center;

  a {
    color: inherit;
    font-weight: bold;
  }
`
