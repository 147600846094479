/* eslint-disable react/prop-types */
import React, { FC } from 'react'
import styled from 'styled-components'

import { ReverseConfig } from './types'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'

type Props = {
  config: ReverseConfig
  onContinue: Function
}

const FrontPage: FC<Props> = ({ config, onContinue }) => {
  return (
    <Wrapper>
      <PaddedContent large>
        <Content>
          <Text mb={30} size="lg" weight="normal">
            {config.title}-test
          </Text>
          <Text size="sm" mb={30}>
            Tjek dine symptomer for {config.title}
          </Text>
          <Button onClick={() => onContinue()} color="primary">
            Start
          </Button>
        </Content>
      </PaddedContent>
    </Wrapper>
  )
}

export default FrontPage

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  text-align: center;
`
