import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Text from 'ui/components/generic/Text'

const usps = [
  'Tjek dine symptomer på 2 minutter',
  '100% gratis og anonymt',
  'Udviklet af læger, baseret på AI'
]

export default class UspSlider extends Component {
  state = {
    current: 0,
    prev: null
  }

  componentDidMount() {
    this.interval = setInterval(this.nextSlide, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  nextSlide = () => {
    const { current } = this.state
    const next = current >= usps.length - 1 ? 0 : current + 1

    this.setState({ prev: current, current: next })
  }

  render() {
    const { current, prev } = this.state
    return (
      <Wrapper>
        {usps.map((usp, index) => (
          <Usp key={index} active={index === current} prev={index === prev}>
            {usp}
          </Usp>
        ))}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  position: relative;
  height: 30px;
`

const Usp = styled(Text).attrs({ size: 'xs' })`
  display: flex;
  align-content: center;
  justify-content: center;

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  text-align: left;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 5px 0;
  transition: 0ms;
  transform: translateX(30px);
  opacity: 0;

  ${props =>
    props.prev &&
    css`
      transition: 500ms;
      transform: translateX(-10px);
    `}

  ${props =>
    props.active &&
    css`
      transition: 500ms;
      transform: translateX(0);
      opacity: 1;
    `}
`
