export default store => next => action => {
  const result = next(action)
  sessionStorage.setItem(
    'userInfo',
    JSON.stringify(getPersistentState(store.getState()))
  )
  return result
}

export const loadPersistentState = store => ({
  ...store,
  ...JSON.parse(sessionStorage.getItem('userInfo'))
})

export const getPersistentState = state => ({
  patientInfo: state.patientInfo,
  symptoms: state.symptoms
})
