import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

const withSearchedSymptoms = (
  getSearch = props => props.search,
  getPatientInfo = props => props.patientInfo,
  key = 'searchedSymptoms'
) => WrappedComponent =>
  class WithSearchedSymptoms extends Component {
    render() {
      const patientInfo = getPatientInfo(this.props)
      return (
        <Query
          variables={{
            phrase: getSearch(this.props),
            sex: patientInfo.gender,
            interviewId: patientInfo.interviewId,
            type: 'symptom'
          }}
          ssr={false}
          query={gql`
            query searchedSymptoms {
              searchedSymptoms(
                phrase: $phrase
                type: $type
                interviewId: $interviewId
              )
                @rest(
                  type: "SearchedSymptom"
                  path: "search?{args}"
                  method: "GET"
                ) {
                id
                commonName
                name
                match
                parentId
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${key}Loading`]: loading,
                    [key]: !loading && data[key]
                  }}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }

export default withSearchedSymptoms
