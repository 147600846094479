import { WINDOW_RESIZE } from '../../actions'
import theme from 'ui/styles/theme'

const getBreakpointFromWidth = width => {
  const { breakpoints } = theme.grid
  const sortedBreakpoints = Object.keys(breakpoints).sort((a, b) =>
    breakpoints[a] > breakpoints[b] ? 1 : 0
  )

  let breakpoint = sortedBreakpoints
    // Sort from largest to smallest
    .sort((a, b) => (breakpoints[a] < breakpoints[b] ? 1 : 0))

    // Find first matching breakpoint
    .find(key => width >= breakpoints[key])

  // If no match, we're at the smallest breakpoint, which should be the last in the list - use that
  if (!breakpoint) {
    breakpoint = sortedBreakpoints[sortedBreakpoints.length - 1]
  }
  return breakpoint
}

const initialState = {
  breakpoint: getBreakpointFromWidth(
    typeof window !== 'undefined' ? window.innerWidth : 1200
  ),
  width: 1200,
  height: 800
}

export default (state = initialState, action) => {
  switch (action.type) {
    case WINDOW_RESIZE:
      return {
        breakpoint: getBreakpointFromWidth(action.payload.width),
        width: action.payload.width,
        height: action.payload.height
      }
    default:
      return state
  }
}

export const setDimensions = (width, height) => ({
  type: WINDOW_RESIZE,
  payload: {
    width,
    height
  }
})
