import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import withCovid19 from 'containers/withCovid19'
import { SlideInOut } from 'ui/styles/poses'
import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Text from 'ui/components/generic/Text'

const Done = ({ onEnd, covid19 }) => {
  return (
    <Content key="done">
      <Avatar src="/images/oda.png" />
      <PaddedContent>
        <Body dangerouslySetInnerHTML={{ __html: covid19.resultDisclaimer }} />
        <Action>
          <Button onClick={onEnd} color="primary">
            Jeg er indforstået
          </Button>
        </Action>
      </PaddedContent>
    </Content>
  )
}

Done.propTypes = {
  onEnd: PropTypes.func,
  covid19: PropTypes.object
}

export default withCovid19()(Done)

const Content = styled(SlideInOut)`
  width: 100%;
  margin: auto;
  text-align: center;
`

const Body = styled(Text).attrs({ size: 'sm' })`
  p {
    margin-bottom: 20px;
  }
`

const Action = styled.div`
  padding-top: 30px;
`

const Avatar = styled.img`
  position: relative;
  bottom: auto;
  margin: 20px auto;
  width: 100px;
  pointer-events: none;
  transition: 1000ms;

  ${media.md`
    width: 140px;
  `}

  ${media.lg`
    position: absolute;
    bottom: 0px;
    margin: 0;
    left: 20vw;
  `}
`
