import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import { push } from 'utils/gtm'
import withDiagnosis from 'containers/withDiagnosis'
import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'

import PaddedContent from 'ui/components/generic/PaddedContent'
import IconSundhedDK from 'ui/components/generic/IconSundhedDK'
import Text from 'ui/components/generic/Text'

import Triage from './Triage'
import Corona from './screenings/Corona'
import Cancer from './screenings/Cancer'
import Diabetes from './screenings/Diabetes'
import Recap from './Recap'
import Conditions from './Conditions'
import Feedback from './Feedback'
import Actions from './Actions'

class Results extends Component {
  static propTypes = {
    diagnosis: PropTypes.oneOfType([
      PropTypes.shape({
        conditions: PropTypes.array,
      }),
      PropTypes.bool,
    ]),
    reset: PropTypes.func,
    symptoms: PropTypes.array,
  }

  componentDidMount() {
    this.track()
  }

  track() {
    const { diagnosis, symptoms } = this.props

    // Track no matching conditions
    if (diagnosis) {
      const conditions = diagnosis.conditions.filter(
        (condition) => condition.probability > 0.2
      )
      if (conditions.length === 0) {
        push({ event: 'noMatchingConditions', symptoms })
      } else {
        push({ event: 'matchingConditions', conditions, symptoms })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.diagnosisLoading && this.props.diagnosis) {
      this.track()
    }
  }

  reset = () => {
    this.props.reset()
  }

  render() {
    const { diagnosis, symptoms } = this.props

    if (!diagnosis) return null

    // filter out irrelevant conditions
    const conditions = diagnosis.conditions.filter(
      (condition) => condition.probability > 0.2
    )

    // generate probability lists
    const highProbability = conditions.filter(
      (condition) => condition.probability >= 0.9
    )
    const mediumProbability = conditions.filter(
      (condition) => condition.probability < 0.9 && condition.probability >= 0.5
    )
    const lowProbability = conditions.filter(
      (condition) => condition.probability < 0.5
    )

    const initialSymptoms = symptoms.filter((s) => s.initial)

    return (
      <Wrapper>
        <PaddedContent>
          <Header>Resultat</Header>
          <Corona />
          <Triage />
          <Conditions
            conditions={highProbability}
            header={
              <div>
                De angivne symptomer passer <strong>i høj grad</strong> på disse
                sygdomme
              </div>
            }
          />
          <Conditions
            conditions={mediumProbability}
            header={
              <div>
                De angivne symptomer passer <strong>i nogen grad</strong> til
                disse sygdomme
              </div>
            }
          />
          <Conditions
            conditions={lowProbability}
            header={
              <div>
                De angivne symptomer passer <strong>i lav grad</strong> til
                disse sygdomme
              </div>
            }
          />
          <Cancer />
          <Diabetes />
          {conditions.length > 0 && <Feedback />}
          <Recap />
          {conditions.length === 0 && (
            <NoResults>
              <Text size="xs">
                <p>
                  Oda kunne ikke i tilstrækkelig grad kvalificere et svar på
                  baggrund af dine angivne symptomer. Det kan skyldes flere
                  årsager:
                </p>
                <ul>
                  <li>
                    At dine symptomer er meget generelle og således kan skyldes
                    en række forskellige lidelser.
                  </li>
                  <li>
                    At du oplever dine symptomer anerledes end de fleste. Oda
                    baserer svar og resultater på de mest udbredte symptomer og
                    beskrivelser, men nogle sygdomme kan opleves meget
                    forskelligt fra menneske til menneske.
                  </li>
                  <li>
                    At din lidelse eller et eller flere af dine symptomer endnu
                    ikke er understøttet og kendt af Oda.
                  </li>
                </ul>

                {initialSymptoms.map((symptom) => (
                  <Action
                    href={`https://www.sundhed.dk/borger/patienthaandbogen/soeg/?SearchTerm=${symptom.name}`}
                    target="_blank"
                  >
                    Søg efter "<strong>{symptom.name}</strong>" på sundhed.dk
                    <IconSundhedDK />
                  </Action>
                ))}
                <p>
                  Hvis du har fundet en fejl eller har en kommentar til
                  interviewet kan du kontakte os på{' '}
                  <a href="mailto:kontakt@odagroup.dk">kontakt@odagroup.dk</a>
                </p>
              </Text>
            </NoResults>
          )}
          <Actions />
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withDiagnosis()
)(Results)

const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.navHeight + 30}px;
  padding-bottom: ${(props) => props.theme.navHeight + 30}px;
`

const Header = styled(Text).attrs({ as: 'h1' })``

const NoResults = styled.div`
  background-color: #fff;
  padding: 30px;
  border-top: 2px solid ${(props) => props.theme.colors.primary};
  margin-bottom: 50px;

  ul {
    margin-bottom: 30px;
  }
`

const Action = styled.a`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.tones.lighter};
  padding: 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  color: inherit;

  svg {
    display: block;
    margin-top: 5px;
    width: 50px;
  }
`
