/* eslint-disable react/prop-types */
import React, { FC, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { reverse } from 'named-urls'

import routes from 'routes'
import { Symptom, updateSymptom } from 'redux/modules/symptoms'
import SingleQuestion from 'ui/components/views/SingleQuestion'
import { ReverseConfig } from './types'

type Props = {
  config: ReverseConfig
  onContinue: Function
  symptoms: Symptom[]
}

const Questions: FC<Props> = ({ config, onContinue, symptoms }) => {
  if (!config) throw new Error('No config')

  const { questionIndex, condition } = useParams<{
    questionIndex: string | undefined
    condition: string
  }>()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const index = questionIndex ? parseInt(questionIndex, 10) : 0

  const symptom = config.initialSymptoms[index]

  const onAnswer = useCallback(
    (symptom, choiceId, remove) => {
      dispatch(updateSymptom(symptom, choiceId, 'initial', remove))
      if (config.initialSymptoms.length > index + 1) {
        push(
          reverse(routes.reverse.interview, {
            condition,
            questionIndex: index + 1,
          })
        )
      } else {
        console.log('No questions left')
        onContinue()
      }
    },
    [config, index, push, onContinue, condition, dispatch]
  )

  return (
    <Wrapper>
      <SingleQuestion
        text={symptom.question}
        items={[
          {
            id: symptom.id,
            choices: [
              { id: 'present', label: 'Ja' },
              { id: 'absent', label: 'Nej' },
              { id: 'unknown', label: 'Ved ikke' },
            ],
          },
        ]}
        getChoiceId={(item: { id: string }) =>
          symptoms.find((s) => s.id === item.id)?.choiceId
        }
        onAnswer={onAnswer}
      />
    </Wrapper>
  )
}

export default Questions

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`
