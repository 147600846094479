import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'

import theme from 'ui/styles/theme'
import withDiagnosis from 'containers/withDiagnosis'
import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'

import Text from 'ui/components/generic/Text'
import IconChevron from 'ui/components/generic/IconChevron'

class Recap extends Component {
  static propTypes = {
    symptoms: PropTypes.array,
    patientInfo: PropTypes.object,
    reset: PropTypes.func
  }

  state = {
    expanded: false,
    contentHeight: 0
  }

  componentDidMount() {
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
  }

  componentDidUpdate() {
    this.updateHeight()
  }

  compnentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  updateHeight = () => {
    const { contentHeight } = this.state

    if (this.content) {
      const clientHeight = this.content.clientHeight
      if (clientHeight !== contentHeight) {
        this.setState({
          contentHeight: clientHeight
        })
      }
    }
  }

  reset = () => {
    this.props.reset()
  }

  render() {
    const { symptoms, patientInfo } = this.props
    const { expanded, contentHeight } = this.state

    const initialSymptoms = symptoms.filter(s => s.source === 'initial')
    const inferredSymptoms = symptoms.filter(s => s.source !== 'initial')
    const presentSymptoms = inferredSymptoms.filter(
      s => s.choiceId === 'present'
    )
    const absentSymptoms = inferredSymptoms.filter(s => s.choiceId === 'absent')
    const unknownSymptoms = inferredSymptoms.filter(
      s => s.choiceId === 'unknown'
    )

    return (
      <Wrapper>
        <HeaderBox onClick={() => this.setState({ expanded: !expanded })}>
          <Header>
            Opsummering
            <ExpandIndicator>
              <Text size="xxs" as="span">
                {expanded ? 'Luk' : 'Læs mere'}
              </Text>
              <IconChevron
                direction={expanded ? 'up' : 'down'}
                color={theme.colors.primary}
              />
            </ExpandIndicator>
          </Header>
        </HeaderBox>
        <Outer height={expanded ? contentHeight : 0}>
          <Inner ref={el => (this.content = el)}>
            <Box expanded={expanded}>
              <Section>
                <SubHeader>Baggrundsinformation</SubHeader>
                <Text size="sm">
                  {patientInfo.gender === 'male' ? 'Mand' : 'Kvinde'} på{' '}
                  {patientInfo.age} år
                </Text>
              </Section>
              <Section>
                <SubHeader>Angivne symptomer</SubHeader>
                {initialSymptoms.map(s => (
                  <Symptom key={s.id}>{s.name}</Symptom>
                ))}
              </Section>
              <Section>
                <SubHeader>Bekræftede symptomer</SubHeader>
                {presentSymptoms.map(s => (
                  <Symptom key={s.id}>{s.name}</Symptom>
                ))}
              </Section>
              <Section>
                <SubHeader>Afviste symptomer</SubHeader>
                {absentSymptoms.map(s => (
                  <Symptom key={s.id}>{s.name}</Symptom>
                ))}
              </Section>
              {unknownSymptoms.length > 0 && (
                <Section>
                  <SubHeader>Ukendte symptomer</SubHeader>
                  {unknownSymptoms.map(symptom => (
                    <Symptom key={symptom.id}>{symptom.name}</Symptom>
                  ))}
                </Section>
              )}
              <Section>
                <Text size="xs">
                  <p>
                    Hvis du tror, vi har misforstået nogle af dine symptomer kan
                    du gennemse og eventuelt ændre nogle af dine svar ved bruge
                    knappen nedenfor. Du kan også starte forfra.
                  </p>
                  <p>
                    I alle tilfælde anbefales du at søge læge, hvis du er syg
                    eller har ondt.
                  </p>
                </Text>
              </Section>
            </Box>
          </Inner>
        </Outer>
      </Wrapper>
    )
  }
}

export default compose(withPatientInfo, withSymptoms, withDiagnosis())(Recap)

const Wrapper = styled.div`
  margin-bottom: 29px;
`

const Box = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 1px;
`

const HeaderBox = styled(Box)`
  cursor: pointer;
`

const Header = styled(Text).attrs({
  as: 'h2'
})`
  position: relative;
  margin-bottom: 0;
  padding-right: 110px;
`

const SubHeader = styled(Text).attrs({
  size: 'xs',
  as: 'h3'
})`
  margin-bottom: 5px;
`

const Symptom = styled(Text).attrs({ size: 'xs' })``

const Section = styled.div`
  margin-bottom: 20px;
`

const Outer = styled.div`
  overflow: hidden;
  transition: height 500ms;
  height: ${props => props.height}px;
`

const Inner = styled.div``

const ExpandIndicator = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;

  span {
    display: block;
    text-transform: uppercase;
    margin-right: 15px;
  }
`
