import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { compose } from 'redux'
import { reverse } from 'named-urls'

import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'

import routes from 'routes'
import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'

import SingleQuestion from 'ui/components/views/SingleQuestion'
import Intro from './Intro'

import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

const questions = [
  {
    id: 'age',
    text: 'Angiv din alder',
    choices: [
      {
        id: 'low',
        label: '18-49 år',
        points: 0,
      },
      {
        id: 'medium',
        label: '50 - 59 år',
        points: 5,
      },
      {
        id: 'mediumHigh',
        label: '60 - 69 år',
        points: 9,
      },
      {
        id: 'high',
        label: '70 år eller ældre',
        points: 13,
      },
    ],
    inferAnswer: (symptoms, patientInfo) => {
      const { age } = patientInfo

      if (!age) return null

      if (age <= 49) return 'low'

      if (age >= 50 && age <= 59) return 'medium'

      if (age >= 60 && age <= 69) return 'mediumHigh'

      if (age >= 70) return 'high'

      return null
    },
  },
  {
    id: 'gender',
    text: 'Angiv dit køn',
    choices: [
      {
        id: 'male',
        label: 'Mand',
        points: 1,
      },
      {
        id: 'female',
        label: 'Kvinde',
        points: 0,
      },
    ],
    inferAnswer: (symptoms, patientInfo) => patientInfo.gender,
  },
  {
    id: 'relatives',
    text:
      'Har du en forælder, en bror, en søster og/eller et barn med type 1- eller type 2-diabetes?',
    choices: [
      {
        id: 'yes',
        label: 'Ja',
        points: 5,
      },
      {
        id: 'no',
        label: 'Nej',
        points: 0,
      },
    ],
    inferAnswer: () => null,
  },
  {
    id: 'hypertension',
    text:
      'Har du nogensinde fået at vide af en læge eller sygeplejerske, at du har for højt blodtryk?',
    choices: [
      {
        id: 'yes',
        label: 'Ja',
        points: 5,
      },
      {
        id: 'no',
        label: 'Nej',
        points: 0,
      },
    ],
    inferAnswer: (symptoms) => {
      const targetSymptoms = [
        'p_9', // Forhøjet blodtryk (risk factor)
        's_543', // Hypertension
      ]
      if (
        symptoms.find(
          (s) => targetSymptoms.includes(s.id) && s.choiceId === 'present'
        )
      )
        return 'yes'

      return null
    },
  },
  // {
  //   id: 'physicallyActive',
  //   text: 'Er du fysisk aktiv?',
  //   choices: [
  //     {
  //       id: 'yes',
  //       label: 'Ja',
  //       points: 0
  //     },
  //     {
  //       id: 'no',
  //       label: 'Nej',
  //       points: 1
  //     }
  //   ]
  // },
  {
    id: 'ethicity',
    text: 'Hvordan vil du beskrive din etniske baggrund?',
    choices: [
      {
        id: 'white',
        label: 'Hvid europæer',
        points: 0,
      },
      {
        id: 'other',
        label: 'Anden etnisk herkomst',
        points: 6,
      },
    ],
    inferAnswer: () => null,
  },
  {
    id: 'bmi',
    text: 'Hvilken BMI (Body Mass Index)-gruppe tilhører du?',
    explication: [
      'Under 18.5: Undervægt',
      '18.5-24.9: Normal vægt',
      '25-29.9: Overvægt',
      '30 - 34.9: Fedme',
      '35 - 39,9: Svær fedme',
      'BMI > 40: Ekstrem overvægt',
    ].join('<br/>'),
    choices: [
      {
        id: 'low',
        label: 'Under 25',
        points: 0,
      },
      {
        id: 'medium',
        label: '25-29',
        points: 3,
      },
      {
        id: 'high',
        label: '30-34',
        points: 5,
      },
      {
        id: 'veryHigh',
        label: '35 eller højere',
        points: 8,
      },
    ],
    inferAnswer: (symptoms) => {
      const targetSymptoms = [
        'p_7', // Forhøjet BMI (risk factor)
      ]
      if (
        symptoms.find(
          (s) => targetSymptoms.includes(s.id) && s.choiceId === 'present'
        )
      )
        return 'high'

      return null
    },
  },
  {
    id: 'waist',
    text: 'Hvad er dit taljemål',
    explication: [
      'Under 90 cm: Normal',
      '90-99 cm: Mellem',
      '100-109 cm: Højt',
      'Over 110cm: Meget højt',
    ].join('<br/>'),
    choices: [
      {
        id: 'low',
        label: 'Under 90 cm',
        points: 0,
      },
      {
        id: 'medium',
        label: '90-99 cm',
        points: 4,
      },
      {
        id: 'high',
        label: '100-109 cm',
        points: 6,
      },
      {
        id: 'veryHigh',
        label: '110 cm eller mere',
        points: 9,
      },
    ],
    inferAnswer: () => null,
  },
]

class DiabetesRiskAssesment extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const { patientInfo, symptoms } = props

    const answers = {}

    // infer answers from already given evidence
    questions.forEach((q) => {
      answers[q.id] = q.inferAnswer(symptoms, patientInfo)
    })

    this.state = { answers }
  }

  getCurrentQuestion() {
    const step = this.getStep()

    if (!step || step === 'result') return null

    return questions[step - 1]
  }

  handleAnswer = (question, id) => {
    const {
      history: { push },
    } = this.props

    // Update answers
    this.setState(
      ({ answers }) => ({ answers: { ...answers, [question.id]: id } }),
      () => {
        // Determine next step
        const nextStep = this.getNextStep()
        push(reverse(routes.diabetesRiskAssesment, { step: nextStep }))
      }
    )
  }

  getStep = () => {
    const step = this.props.match.params.step

    return isNaN(step) ? step : parseInt(step, 10)
  }

  getNextStep = () => {
    const step = this.getStep()

    const unansweredQuestions = this.getUnansweredQuestions()

    if (step < questions.length) {
      return step + 1
    }

    if (unansweredQuestions.length) {
      return questions.findIndex((q) => q.id === unansweredQuestions[0].id)
    }

    return 'result'
  }

  getPoints = () => {
    const { answers } = this.state

    let points = 0

    Object.keys(answers).forEach((key) => {
      const question = questions.find((q) => q.id === key)
      const answer = question.choices.find((c) => c.id === answers[key])
      if (answer) {
        points += answer.points
      }
    })

    return points
  }

  getUnansweredQuestions = () => {
    const { answers } = this.state

    return questions.filter((q) => !answers[q.id])
  }

  getRiskGroup = () => {
    const points = this.getPoints()

    if (points < 7) return 'low'

    if (points < 16) return 'moderate'

    if (points < 25) return 'high'

    return 'veryHigh'
  }

  getRiskText = () => {
    const group = this.getRiskGroup()

    switch (group) {
      case 'low':
        return 'Lav'
      case 'moderate':
        return 'Moderat'
      case 'high':
        return 'Høj'
      case 'veryHigh':
        return 'Meget høj'
      default:
        return 'ukendt'
    }
  }

  render() {
    const step = this.getStep()

    const { answers } = this.state

    const question = this.getCurrentQuestion()

    const unansweredQuestions = this.getUnansweredQuestions()

    const points = this.getPoints()
    const riskGroup = this.getRiskGroup()
    const riskText = this.getRiskText()

    return (
      <Wrapper>
        <SlideInOutGroup>
          {!step && (
            <Content key="intro">
              <Intro />
            </Content>
          )}
          {step && question && (
            <Content key={`question-${step}`}>
              <SingleQuestion
                text={question.text}
                items={[question]}
                getChoiceId={(id) => answers[question.id]}
                onAnswer={this.handleAnswer}
                explication={question.explication}
              />
            </Content>
          )}
          {step && !question && unansweredQuestions.length === 0 && (
            <Content key="result">
              <Text as="p">
                Mange tak for hjælpen med denne test. Dine svar har givet
                følgende resultat.
              </Text>
              <Risk riskGroup={riskGroup}>
                <Score>
                  <Label>Risiko-score</Label>
                  <Points>{points}</Points>
                </Score>
                <RiskText>{riskText} risiko</RiskText>
              </Risk>
            </Content>
          )}

          {step && !question && unansweredQuestions.length > 0 && (
            <Content key="result">
              <h1>Der opstod en fejl</h1>
              <Text as="p">Prøv venligst at starte screeningen forfra.</Text>
              <p>
                <Button to={reverse(routes.diabetesRiskAssesment)}>
                  Start forfra
                </Button>
              </p>
            </Content>
          )}
        </SlideInOutGroup>
      </Wrapper>
    )
  }
}

export default compose(withSymptoms, withPatientInfo)(DiabetesRiskAssesment)

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled(SlideInOut)`
  width: 100%;
  margin: auto;
  text-align: center;
`

const Risk = styled.div`
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  flex-direction: column;

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: auto;

  ${(props) =>
    props.riskGroup === 'low' &&
    css`
      background-color: #41ad49;
    `}

  ${(props) =>
    props.riskGroup === 'moderate' &&
    css`
      background-color: #ffcb05;
    `}

  ${(props) =>
    props.riskGroup === 'high' &&
    css`
      background-color: #ff6f05;
    `}

  ${(props) =>
    props.riskGroup === 'veryHigh' &&
    css`
      background-color: #dd020e;
    `}
`

const Score = styled.div``

const Points = styled.div`
  font-size: 80px;
`

const Label = styled.div`
  font-size: 10px;
`

const RiskText = styled.div``
