import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { reverse } from 'named-urls'
import { withRouter } from 'react-router'

import withPatientInfo from 'containers/withPatientInfo'
import withSymptoms from 'containers/withSymptoms'
import withSuggestedSymptoms from 'containers/withSuggestedSymptoms'
import routes from 'routes'

import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'
import Spinner from 'ui/components/generic/Spinner'
import GroupMultipleQuestion from 'ui/components/views/GroupMultipleQuestion'

class RelatedSymptoms extends Component {
  static propTypes = {
    suggestedSymptoms: PropTypes.array,
    suggestedSymptomsLoading: PropTypes.bool.isRequired,
    symptoms: PropTypes.array.isRequired,
    updateSymptoms: PropTypes.func.isRequired,
    setChoiceId: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      suggestedSymptomsLoading,
      suggestedSymptoms,
      onContinue,
    } = this.props
    if (!suggestedSymptomsLoading && suggestedSymptoms.length === 0) {
      onContinue()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.suggestedSymptomsLoading &&
      !this.props.suggestedSymptomsLoading &&
      this.props.suggestedSymptoms.length === 0
    ) {
      this.props.onContinue()
    }
  }

  answer = (symptoms) => {
    const { updateSymptoms, onContinue } = this.props
    updateSymptoms(symptoms.map((s) => ({ ...s, source: 'suggest' })))
    onContinue()
  }

  getChoiceId = (symptom) => {
    const { symptoms } = this.props
    const matchingSymptom = symptoms.find((s) => s.id === symptom.id)
    if (matchingSymptom) {
      return matchingSymptom.choiceId
    }
    return null
  }

  render() {
    const { suggestedSymptomsLoading, suggestedSymptoms } = this.props

    return (
      <Wrapper>
        <SlideInOutGroup>
          {suggestedSymptomsLoading && (
            <Content key="loading">
              <Spinner />
            </Content>
          )}
          {!suggestedSymptomsLoading && (
            <Content key="question">
              <GroupMultipleQuestion
                text="Oplever du nogle af følgende symptomer?"
                items={suggestedSymptoms}
                onAnswer={this.answer}
                getChoiceId={this.getChoiceId}
              />
            </Content>
          )}
        </SlideInOutGroup>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withSuggestedSymptoms()
)(RelatedSymptoms)

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled(SlideInOut)`
  width: 100%;
  margin: auto;
  text-align: center;
`
