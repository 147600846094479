import React from 'react'
import styled from 'styled-components'
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reverse } from 'named-urls'

import { StateType as Symptoms } from 'redux/modules/symptoms'
import { StateType as PatientInfo } from 'redux/modules/patientInfo'

import routes from 'routes'
import { setAge, setGender } from 'redux/modules/patientInfo'

import diabetes2 from './conditions/diabetes2'

import AnimatedRoutes from 'ui/components/AnimatedRoutes'
import GenderSelect from 'ui/components/views/GenderSelect'
import AgeSelect from 'ui/components/views/AgeSelect'
import FrontPage from './FrontPage'
import Questions from './Questions'
import Results from './Results'
import { ReverseConfig } from './types'
import { SlideInOut, SlideInOutGroup } from 'ui/styles/poses'

const Reverse = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { condition, questionIndex } = useParams<{
    condition: string | undefined
    questionIndex: string | undefined
  }>()

  const patientInfo = useSelector(
    (state: { patientInfo: PatientInfo }) => state.patientInfo
  )

  const symptoms = useSelector(
    (state: { symptoms: Symptoms }) => state.symptoms.symptoms
  )

  const config = getConfig(condition)

  if (!config) {
    return (
      <Page>
        <h1>Der skete en fejl</h1>
      </Page>
    )
  }

  const next = (current: string) => {
    const routeNames = Object.keys(routes.reverse)
    const index = routeNames.findIndex((route) => route === current)
    const nextRoute = routeNames[index + 1]

    if (nextRoute) {
      push(reverse(routes.reverse[nextRoute], { condition }))
    }
  }

  return (
    <Wrapper>
      <SlideInOutGroup>
        <SlideInOut key={location.pathname}>
          <Switch location={location} key={location.pathname}>
            <Route exact path={routes.reverse.frontPage}>
              <Page key="frontPage">
                <FrontPage
                  onContinue={() => next('frontPage')}
                  config={config}
                />
              </Page>
            </Route>
            <Route exact path={routes.reverse.genderSelect}>
              <Page key="genderSelect">
                <GenderSelect
                  gender={patientInfo.gender}
                  onChange={(gender: 'male' | 'female' | null) => {
                    dispatch(setGender(gender))
                    next('genderSelect')
                  }}
                />
              </Page>
            </Route>
            <Route exact path={routes.reverse.ageSelect}>
              <Page key="ageSelect">
                <AgeSelect
                  age={patientInfo.age}
                  onChange={(age: number | null) => {
                    dispatch(setAge(age))
                    next('ageSelect')
                  }}
                />
              </Page>
            </Route>
            <Route exact path={routes.reverse.interview}>
              <Page key={`interview-${questionIndex || 0}`}>
                <Questions
                  onContinue={() => next('interview')}
                  config={config}
                  symptoms={symptoms}
                />
              </Page>
            </Route>
            <Route path={routes.reverse.results}>
              <Page key="results">
                <Results
                  config={config}
                  symptoms={symptoms}
                  patientInfo={patientInfo}
                />
              </Page>
            </Route>
          </Switch>
        </SlideInOut>
      </SlideInOutGroup>
    </Wrapper>
  )
}

export default Reverse

const getConfig = (condition?: string): ReverseConfig | null => {
  switch (condition) {
    case 'diabetes2':
      return diabetes2
    default:
      return null
  }
}

const Wrapper = styled.div`
  /* height: 100vh;
  width: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch; */
`

const Page = styled.div``
