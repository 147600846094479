import styled, { css } from 'styled-components'

import responsiveText from 'ui/styles/responsiveText'

export default styled.div<{
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  weight?: 'thin' | 'light' | 'normal' | 'bold' | 'black'
  mt?: number
  mb?: number
  ml?: number
  mr?: number
  align?: 'center' | 'left' | 'right'
}>`
  /**
  * Font size
  **/
  ${(props) => responsiveText(props.size)}

  /**
  * Font-weight
  **/
  ${(props) =>
    props.weight &&
    css`
      font-weight: ${props.theme.fontWeights[props.weight] || 'normal'};
    `}

  /**
  * Color
  **/
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  /**
  * Margin
  **/
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt}px;
    `}

  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}px;
    `}

  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}px;
    `}

  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr}px;
    `}

  /**
  * Align
  **/
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`

export const BodyText = styled.div`
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  color: ${(props) => props.theme.colors.tones.light};

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
    color: ${(props) => props.theme.colors.tones.dark};
  }

  h1 {
    margin-bottom: 40px;

    font-size: 30px;
    line-height: 40px;
  }

  h2 {
    margin-bottom: 15px;

    font-size: 22px;
    line-height: 34px;
  }

  p {
    margin-bottom: 40px;
  }

  ul,
  ol {
    margin: 0;
    list-style-position: outside;
    padding-left: 20px;
    margin-bottom: 40px;

    li {
      padding-left: 60px;
      margin-bottom: 15px;

      p {
        margin-bottom: 0;
        color: ${(props) => props.theme.colors.tones.light};
        font-size: 22px;
      }
    }
  }
`
