import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class HeadComponent extends Component {
  render() {
    const { seo, noIndex } = this.props
    if (!seo) return null
    return (
      <Helmet>
        {noIndex && <meta name="robots" content="noindex" />}
        <title>{seo.metaTitle}</title>
        <meta name="description" content={seo.metaDescription} />
        <meta property="og:site_name" content={seo.metaTitle} />
        <meta property="og:image" content={seo.ogImage} />
        <meta property="og:locale" content={seo.locale} />
        <meta property="og:title" content={seo.ogTitle} />
        <meta property="og:description" content={seo.ogDescription} />
        <meta property="twitter:image" content={seo.twitterImage} />
        <meta property="twitter:title" content={seo.twitterTitle} />
        <meta property="twitter:description" content={seo.twitterDescription} />
      </Helmet>
    )
  }
}
