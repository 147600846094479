import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'styled-bootstrap-grid'

const PaddedContent = ({ children, large, ...rest }) => (
  <Container fluid {...rest}>
    <Row>
      <Col
        md={large ? 10 : 8}
        mdOffset={large ? 1 : 2}
        lg={large ? 10 : 8}
        lgOffset={large ? 1 : 2}
        xl={large ? 8 : 6}
        xlOffset={large ? 2 : 3}
      >
        {children}
      </Col>
    </Row>
  </Container>
)

PaddedContent.propTypes = {
  children: PropTypes.node,
  large: PropTypes.bool,
}

export default PaddedContent
