import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'

const entry = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

const Wrapper = styled.div`
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  font-family: monospace;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  z-index: 9999999;
  border-radius: 5px;
  border: 2px solid
    ${props => props.theme && props.theme.colors && props.theme.colors.primary};
  animation: ${entry} 500ms both;

  h2 {
    color: red;
  }
`

class Error extends Component {
  componentDidMount() {
    console.error(this.props.children)
  }

  render() {
    const { header } = this.props

    return (
      process.env.NODE_ENV !== 'production' && (
        <Wrapper>
          <h2>{header || 'Error'}:</h2>
          {this.props.children}
        </Wrapper>
      )
    )
  }
}

export default Error
