import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { reverse } from 'named-urls'
import { withRouter } from 'react-router'

import withPatientInfo from 'containers/withPatientInfo'
import withSymptoms from 'containers/withSymptoms'
import routes from 'routes'

import GroupMultipleQuestion from 'ui/components/views/GroupMultipleQuestion'

class RelatedSymptoms extends Component {
  static propTypes = {
    patientInfo: PropTypes.shape({
      age: PropTypes.number,
      gender: PropTypes.oneOf('male', 'female'),
    }),
    symptoms: PropTypes.array.isRequired,
    updateSymptoms: PropTypes.func.isRequired,
    setChoiceId: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
  }

  answer = (symptoms) => {
    const { updateSymptoms, onContinue } = this.props
    updateSymptoms(symptoms.map((s) => ({ ...s, source: 'predefined' })))
    onContinue()
  }

  getChoiceId = (symptom) => {
    const { symptoms } = this.props
    const matchingSymptom = symptoms.find((s) => s.id === symptom.id)
    if (matchingSymptom) {
      return matchingSymptom.choiceId
    }
    return null
  }

  getRiskFactors = () => {
    const { gender, age } = this.props.patientInfo
    const specialRiskFactors = []

    if (gender === 'female') {
      if (age < 46) {
        specialRiskFactors.push({
          id: 'p_42',
          name: 'Graviditet',
          question: 'Jeg er gravid',
        })
      } else if (age < 60) {
        specialRiskFactors.push({
          id: 'p_11',
          name: 'Overgangsalder',
          question: 'Jeg er i overgangsalderen',
        })
      }
    }
    return specialRiskFactors.concat(baseRiskFactors)
  }

  render() {
    return (
      <Wrapper>
        <Content key="question">
          <GroupMultipleQuestion
            text="Angiv hvordan følgende udsagn passer til dig"
            items={this.getRiskFactors()}
            onAnswer={this.answer}
            getChoiceId={this.getChoiceId}
          />
        </Content>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms
)(RelatedSymptoms)

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`

const baseRiskFactors = [
  {
    id: 'p_7',
    name: 'BMI over 30',
    question: 'Jeg er overvægtig',
  },
  {
    id: 'p_28',
    name: 'Rygning',
    question: 'Jeg er ryger',
  },
  {
    id: 'p_264',
    name: 'Alvorlig fysisk skade',
    question: 'Jeg har for nyligt været udsat for en alvorlig fysisk skade',
  },
  {
    id: 'p_10',
    name: 'Forhøjet kolesterol',
    question: 'Jeg har forhøjet kolestorol',
  },
  {
    id: 'p_9',
    name: 'Forhøjet blodtryk',
    question: 'Jeg har forhøjet blodtryk',
  },
  {
    id: 'p_8',
    name: 'Diabetes',
    question: 'Jeg har diabetes',
  },
]
