import React, { Component } from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import withSymptoms from 'containers/withSymptoms'
import withTriage from 'containers/withTriage'
import withPatientInfo from 'containers/withPatientInfo'

import Text from 'ui/components/generic/Text'

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.primary};
  border-top: 5px solid ${props => props.theme.colors.primary};
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
`

const Symptoms = styled.div`
  margin-top: 20px;
`

const Symptom = styled(Text).attrs({ size: 'xs' })`
  margin-bottom: 5px;
  font-weight: bold;
`

class Results extends Component {
  getText() {
    const { triage_level } = this.props.triage
    switch (triage_level) {
      case 'emergency_ambulance':
        return (
          <span>
            Nogle af de symptomer, du har angivet er alvorlige. Hvis du oplever
            de angivne symptomer bør du ringe efter en ambulance med det samme.{' '}
            <br />
            <br />
            <a href="tel:112">Ring 112 nu.</a>
          </span>
        )
      case 'emergency':
        return 'Nogle af de symptomer, du har angivet er alvorlige. Hvis du oplever de angivne symptomer bør du straks kontakte din læge eller lægevagten.'
      case 'consultation_24':
        return 'Nogle af de symptomer, du har angivet kan være alvorlige. Du bør kontakte en læge indenfor de næste 24 timer. Hvis dine symptomer pludselig forværres bør du kontakte din egen læge eller lægevagten med det samme.'
      case 'consultation':
        return 'Nogle af de symptomer, du har angivet kan give anledning til, at du bør opsøge en læge inden for den nærmeste fremtid.'
      case 'self_care':
        return 'Ingen af de symptomer, du har angivet giver anledning til at søge læge lige nu. Du bør altid søge læge hvis du føler dig syg eller hvis dine symptomer forværres.'
      default:
        return ''
    }
  }

  render() {
    const { triage } = this.props

    if (!triage) return null

    const { triage_level, serious } = triage

    const seriousLevels = ['emergency_ambulance', 'emergency']

    if (!seriousLevels.includes(triage_level)) return null

    const isEmergency = triage_level.indexOf('emergency') === 0

    const showSymptoms = serious.filter(s =>
      isEmergency ? s.is_emergency : true
    )

    return (
      <Wrapper level={triage_level}>
        <Text size="xs">{this.getText()}</Text>
        {showSymptoms.length > 0 && (
          <Symptoms>
            <Text bold size="xs" as="h4">
              Alvorlige symptomer:
            </Text>
            {showSymptoms.map(symptom => (
              <Symptom>{symptom.common_name}</Symptom>
            ))}
          </Symptoms>
        )}
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withTriage()
)(Results)
