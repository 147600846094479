import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

import Error from 'ui/components/generic/Error'

export default (location, prop = 'menuItems') => WrappedComponent =>
  class WithNav extends Component {
    render() {
      return (
        <Query
          query={gql`
            query($location: MenuLocation) {
              menuItems(where: { location: $location }) {
                nodes {
                  id
                  label
                  url
                  target
                  connectedObject {
                    ... on Page {
                      slug
                    }
                    ... on Post {
                      slug
                    }
                  }
                }
              }
            }
          `}
          variables={{ location }}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [`${prop}Loading`]: loading,
                    [`${prop}`]:
                      !loading && !error
                        ? data.menuItems && data.menuItems.nodes
                        : []
                  }}
                />
              )
            }
            return <Error header="Graphql Error">{error.message}</Error>
          }}
        </Query>
      )
    }
  }
