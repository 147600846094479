import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import posed from 'react-pose'

import { push } from 'utils/gtm'
import uniqBy from 'lodash/uniqBy'
import theme from 'ui/styles/theme'
import { SlideInOutGroup, SlideInOut, transition } from 'ui/styles/poses'
import withPatientInfo from 'containers/withPatientInfo'
import withOverlay from 'containers/withOverlay'
import withSymptoms from 'containers/withSymptoms'
import withSearchedSymptoms from 'containers/withSearchedSymptoms'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

class SearchResults extends Component {
  constructor(props) {
    super(props)

    this.state = { resultsMaxHeight: 200 }

    this.wrapper = React.createRef()
  }

  componentDidMount() {
    this.updateResultsMaxHeight()
    window.addEventListener('resize', this.updateResultsMaxHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateResultsMaxHeight)
  }

  componentDidUpdate(prevProps) {
    this.updateResultsMaxHeight()

    // Track not found symptoms
    const { searchedSymptomsLoading, searchedSymptoms, search } = this.props
    if (
      prevProps.searchedSymptomsLoading &&
      !searchedSymptomsLoading &&
      searchedSymptoms &&
      searchedSymptoms.length === 0
    ) {
      push({ event: 'symptomNotFound', value: search })
    }
  }

  updateResultsMaxHeight = (state = this.state) => {
    if (this.wrapper.current) {
      const { resultsMaxHeight } = state

      // mobile
      let distanceToBottom = window.innerHeight - theme.navHeight - 60

      // desktop
      if (window.innerWidth > theme.grid.breakpoints.xs) {
        // element is 60px, centered vertically and translated up 100 pixels
        const bottom = window.innerHeight / 2 + 30 - 100

        distanceToBottom = window.innerHeight - bottom
      }
      const maxHeight = distanceToBottom - 30

      if (resultsMaxHeight !== maxHeight) {
        this.setState({ resultsMaxHeight: maxHeight })
      }
    }
  }

  suggestSymptom = () => {
    const { search, showOverlay } = this.props
    showOverlay('suggestSymptom', search)
  }

  render() {
    const { searchedSymptoms, updateSymptom } = this.props
    const { resultsMaxHeight } = this.state

    const searchResults = uniqBy(searchedSymptoms, 'id')

    return (
      <Wrapper ref={this.wrapper} height={resultsMaxHeight}>
        <SlideInOutGroup withParent={false} animateOnMount>
          {searchedSymptoms && searchedSymptoms.length > 0 && (
            <Results key="results" maxHeight={resultsMaxHeight}>
              {searchResults.map((symptom, index) => (
                <SymptomWrapper key={`${index}-${symptom.id}`}>
                  <Symptom
                    onClick={() => updateSymptom(symptom, 'present', 'initial')}
                  >
                    {symptom.commonName || symptom.name}
                  </Symptom>
                </SymptomWrapper>
              ))}
            </Results>
          )}
          {searchedSymptoms && searchedSymptoms.length === 0 && (
            <NoResults key="noResults">
              <SlideInOut direction="right" amount={20}>
                <Text size="xs" mb={20}>
                  <p>Vi fandt ikke noget, som matcher din søgning.</p>
                  <p>
                    Prøv eventuelt at formulere dig mere generelt fx.
                    <br />
                    <strong>hånd</strong> i stedet for{' '}
                    <strong>højre hånd</strong>
                    <br />
                    <strong>finger</strong> i stedet for{' '}
                    <strong>lillefinger</strong>
                  </p>
                  <p>Hjælp os med at blive bedre ved at foreslå et symptom</p>
                </Text>
                <Button color="primary" onClick={this.suggestSymptom}>
                  Foreslå symptom
                </Button>
              </SlideInOut>
            </NoResults>
          )}
        </SlideInOutGroup>
      </Wrapper>
    )
  }
}

SearchResults.propTypes = {
  search: PropTypes.string,
  searchedSymptoms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      commonName: PropTypes.string,
      name: PropTypes.string
    })
  ),
  searchedSymptomsLoading: PropTypes.bool,
  updateSymptom: PropTypes.func.isRequired,
  showOverlay: PropTypes.func.isRequired
}

export default compose(
  withOverlay,
  withPatientInfo,
  withSymptoms,
  withSearchedSymptoms()
)(SearchResults)

const ResultsPose = posed.div({
  enter: {
    opacity: 1,
    staggerChildren: 50,
    delayChildren: 200,
    transition
  },
  exit: {
    opacity: 1,
    transition
  },
  pre: {
    opacity: 1,
    transition
  },
  flip: transition
})

const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: ${props => props.height}px;
  z-index: 1;
  overflow: hidden;
  transition: all 500ms;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: ${props => props.height - 50}px;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    pointer-events: none;
  }
`

const SymptomWrapper = styled(SlideInOut).attrs({
  direction: 'down',
  amount: 20
})`
  display: block;
`

const Results = styled(ResultsPose)`
  background-color: #fff;
  max-height: ${props => props.maxHeight}px;
  overflow: hidden;
  overflow-y: auto;
`

const NoResults = styled(ResultsPose)`
  display: block;
  padding: 20px;
  background-color: #fff;
`

const Symptom = styled.div`
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.tones.lightest};
  text-align: left;
  cursor: pointer;
`
