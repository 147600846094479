import {
  REMOVE_SYMPTOM,
  SET_CHOICE_ID,
  ADD_QUESTION,
  UPDATE_SYMPTOMS,
  RESET,
} from '../../actions'

const initialState = {
  symptoms: [],
  answered: null,
  questions: [],
}

const symptoms = (
  state: StateType = initialState,
  action: { type: string; payload: any }
) => {
  const { payload } = action

  switch (action.type) {
    case SET_CHOICE_ID: {
      const index = state.symptoms.findIndex(
        (s: any) => s.id === payload.symptomId
      )
      const symptoms = state.symptoms.concat()
      symptoms[index] = {
        ...symptoms[index],
        choiceId: payload.choiceId,
      }
      return {
        ...state,
        symptoms,
        answered: payload.symptomId,
      }
    }

    case UPDATE_SYMPTOMS: {
      const { set, remove } = payload

      let symptoms = state.symptoms.concat()

      if (set) {
        // update all specified symptoms
        set.forEach(
          (s: {
            symptom: InputSymptom
            choiceId: Symptom['choiceId']
            source: Symptom['source']
          }) => {
            const { symptom, choiceId, source } = s

            // create new symptom object
            const newSymptom = {
              ...parseSymptom(symptom),
              choiceId,
              source,
            }

            // update or add
            const index = symptoms.findIndex(
              (existingSymptom) => existingSymptom.id === symptom.id
            )
            if (index >= 0) {
              symptoms[index] = newSymptom
            } else {
              symptoms.push(newSymptom)
            }
          }
        )
      }

      if (remove) {
        // remove symptoms
        symptoms = symptoms.filter((s) => !remove.includes(s.id))
      }

      return {
        ...state,
        symptoms,
      }
    }
    case REMOVE_SYMPTOM:
      return {
        ...state,
        symptoms: state.symptoms.filter((s) => s.id !== payload),
      }
    case ADD_QUESTION:
      return {
        ...state,
        questions: state.questions.concat(payload),
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

export const updateSymptom = (
  symptom: InputSymptom,
  choiceId: string,
  source?: string,
  remove = []
) => ({
  type: UPDATE_SYMPTOMS,
  payload: { set: [{ symptom, choiceId, source }], remove },
})
export const updateSymptoms = (set: InputSymptom[], remove = []) => ({
  type: UPDATE_SYMPTOMS,
  payload: { set, remove },
})
export const removeSymptom = (id: string) => ({
  type: UPDATE_SYMPTOMS,
  payload: { remove: [id] },
})
export const reset = () => ({ type: RESET })
export const addQuestion = (question: any) => ({
  type: ADD_QUESTION,
  payload: question,
})

export const parseSymptom = (symptom: InputSymptom, source?: string) => {
  // different endpoints have different ways of defining symptoms 🙄
  let name = symptom.name || ''
  if (
    symptom.__typename === 'SearchedSymptom' &&
    (symptom.commonName || symptom.name)
  ) {
    name = symptom.commonName || symptom.name || ''
  } else if (symptom.__typename === 'SuggestedSymptom' && symptom.common_name) {
    name = symptom.common_name
  }

  return {
    name,
    id: symptom.id,
    source,
  }
}

export default symptoms

export type InputSymptom = {
  id: string
  name?: string
  __typename?: string
  commonName?: string
  common_name?: string
}

export type Symptom = {
  name: string
  id: string
  source: 'initial' | 'related' | undefined
  choiceId?: 'present' | 'absent' | 'unknown'
}

export type StateType = {
  symptoms: Symptom[]
  answered: string | null
  questions: any[]
}
