import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { reverse } from 'named-urls'

import withDiagnosis from 'containers/withDiagnosis'
import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'
import withOverlay from 'containers/withOverlay'
import routes from 'routes'
import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'
import Spinner from 'ui/components/generic/Spinner'

import SingleQuestion from 'ui/components/views/SingleQuestion'
import GroupSingleQuestion from 'ui/components/views/GroupSingleQuestion'
import GroupMultipleQuestion from 'ui/components/views/GroupMultipleQuestion'
import Done from './Done'

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled(SlideInOut)`
  width: 100%;
  margin: auto;
  text-align: center;
`

class Interview extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    diagnosis: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    diagnosisLoading: PropTypes.bool,
    symptoms: PropTypes.array,
    questions: PropTypes.array,
    answered: PropTypes.string,
    updateSymptom: PropTypes.func,
    updateSymptoms: PropTypes.func,
    addQuestion: PropTypes.func,
    onContinue: PropTypes.func.isRequired,
  }

  state = {
    loadDebounce: false,
    ignoreEmergency: false,
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.match.params.questionIndex !==
      this.props.match.params.questionIndex
    ) {
      this.setLoading()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      addQuestion,
      diagnosis,
      diagnosisLoading,
      questions,
      overlayVisible,
      showOverlay,
      patientInfo,
    } = this.props

    // Add newly loaded questions to redux
    if (prevProps.diagnosisLoading && !diagnosisLoading) {
      // If we shouldn't stop
      if (!diagnosis.should_stop) {
        // And if we haven't already added this question
        if (
          !questions.find(
            (q) => JSON.stringify(q) === JSON.stringify(diagnosis.question)
          )
        ) {
          addQuestion(diagnosis.question)
        }

        console.log(patientInfo, !patientInfo.ignoreEmergency)

        // check for early triage
        if (
          diagnosis.has_emergency_evidence &&
          !patientInfo.ignoreEmergency &&
          !overlayVisible
        ) {
          showOverlay('earlyTriage')
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.answerTimeout)
    clearTimeout(this.loadTimeout)
  }

  setLoading = () => {
    this.setState({ loadDebounce: true })
    this.loadTimeout = setTimeout(
      () => this.setState({ loadDebounce: false }),
      1000
    )
  }

  getQuestionIndex = () => {
    const { match } = this.props

    return parseInt(match.params.questionIndex, 10) || 0
  }

  continue = () => {
    const {
      history: { push },
    } = this.props

    const questionIndex = this.getQuestionIndex()

    const nextIndex = questionIndex + 1

    console.log(
      nextIndex,
      routes.interview.interview,
      reverse(routes.interview.interview, { questionIndex: nextIndex })
    )

    // Navigate to next question
    push(reverse(routes.interview.interview, { questionIndex: nextIndex }))
  }

  answer = (symptom, choiceId, remove) => {
    const { updateSymptom } = this.props

    updateSymptom(symptom, choiceId, undefined, remove)
    this.setLoading()
    this.answerTimeout = setTimeout(this.continue, 200)
  }

  answerMultiple = (symptoms) => {
    const { updateSymptoms } = this.props
    updateSymptoms(symptoms)
    this.setLoading()
    this.answerTimeout = setTimeout(this.continue, 200)
  }

  getQuestion() {
    const { questions, diagnosis } = this.props

    if (!diagnosis) return null

    let question = null

    const questionIndex = this.getQuestionIndex()

    question = questions[questionIndex]

    if (!question) return null

    switch (question.type) {
      case 'single':
        return (
          <SingleQuestion
            {...question}
            onAnswer={this.answer}
            getChoiceId={this.getChoiceId}
          />
        )
      case 'group_single':
        return (
          <GroupSingleQuestion
            {...question}
            onAnswer={this.answer}
            getChoiceId={this.getChoiceId}
          />
        )
      case 'group_multiple':
        return (
          <GroupMultipleQuestion
            {...question}
            onAnswer={this.answerMultiple}
            getChoiceId={this.getChoiceId}
          />
        )
      default:
        return <pre>type: {question.type}</pre>
    }
  }

  getChoiceId = (symptom) => {
    const { symptoms } = this.props

    const answer = symptoms.find((s) => s.id === symptom.id)
    if (answer) {
      return answer.choiceId
    }
    return null
  }

  render() {
    const { diagnosisLoading, diagnosis, onContinue } = this.props
    const { loadDebounce } = this.state
    const questionIndex = this.getQuestionIndex()

    const question = this.getQuestion()

    const shouldStop = !question && diagnosis && diagnosis.should_stop

    const loading = diagnosisLoading || loadDebounce

    return (
      <Wrapper>
        <SlideInOutGroup>
          {loading && (
            <Content key="loading">
              <Spinner />
            </Content>
          )}
          {!loading && question && (
            <Content key={questionIndex}>{question}</Content>
          )}
          {!loading && shouldStop && (
            <Content key="done">
              <Done onEnd={onContinue} />
            </Content>
          )}
        </SlideInOutGroup>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withDiagnosis(),
  withOverlay
)(Interview)
