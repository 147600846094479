import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import getTranslation from 'utils/getTranslation'
import withTranslations from 'containers/withTranslations'
import RadioButtonComponent from 'ui/components/generic/RadioButton'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'

class GroupSingleQuestion extends Component {
  static propTypes = {
    text: PropTypes.string,
    items: PropTypes.array,
    onAnswer: PropTypes.func,
    getChoiceId: PropTypes.func,
    translations: PropTypes.array
  }

  render() {
    const { text, items, onAnswer, getChoiceId, translations } = this.props

    return (
      <Wrapper>
        <PaddedContent>
          <Text mb={30} size="sm">
            {getTranslation(text, translations) || text}
          </Text>
          <Options>
            {items.map(item => (
              <Option
                key={item.id}
                selected={getChoiceId(item) === 'present'}
                onClick={() =>
                  onAnswer(
                    item,
                    'present',
                    items.filter(i => i.id !== item.id).map(i => i.id)
                  )
                }
              >
                <OptionText>
                  {getTranslation(item.id, translations, 'question') ||
                    item.name}
                </OptionText>
                <RadioButton checked={getChoiceId(item) === 'present'} />
              </Option>
            ))}
          </Options>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default withTranslations()(GroupSingleQuestion)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  p {
    text-align: center;
  }
`

const Options = styled.div`
  margin-bottom: 20px;
`

const RadioButton = styled(RadioButtonComponent)`
  position: absolute;
  top: 50%;
  right: 20px;
  display: none;
`

const Option = styled.div`
  position: relative;
  padding: 20px;
  background-color: ${props =>
    props.selected ? props.theme.colors.primary : '#fff'};
  margin-bottom: 5px;
  cursor: pointer;
  color: ${props => (props.selected ? '#fff' : 'inherit')};
  /* border-left: 1px solid ${props => props.theme.colors.primary}; */

  @media (hover: hover) {
    &:hover {
      background-color: ${props =>
        props.selected
          ? props.theme.colors.primaryDark
          : props.theme.colors.tones.lighter};
      color: ${props => (props.selected ? '#fff' : 'inherit')};
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.primaryDark};
    color: #fff;
  }

  transition: 300ms;
`

const OptionText = styled(Text).attrs({ size: 'xs' })`
  text-align: left;
  padding-right: 45px;
`
