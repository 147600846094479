import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { push } from 'utils/gtm'

import routes from 'routes'
import withSymptoms from 'containers/withSymptoms'
import withTriage from 'containers/withTriage'
import withPatientInfo from 'containers/withPatientInfo'
import withSiteMeta from 'containers/withSiteMeta'

import Link from 'ui/components/generic/FlexibleLink'
import TextComponent from 'ui/components/generic/Text'

const coronaSymptoms = [
  's_1142', // muskelsmerter
  's_98', // feber
  's_20', // ondt i halsen
  's_102', // hoste
  's_81', // kuldegysninger
  's_88', // vejrtrækningsproblemer
  's_8', // diarré
  's_156', // kvalme
  's_305' // opkast
]

class Results extends Component {
  static propTypes = {
    symptoms: PropTypes.array
  }

  getCoronaSymptoms() {
    const { symptoms } = this.props

    if (!symptoms) return []

    return symptoms.filter(
      s => s.choiceId === 'present' && coronaSymptoms.includes(s.id)
    )
  }

  trackOutboundClick(e) {
    push({ event: 'clickConditionExternalLink', value: e.target.href })
  }

  render() {
    const { siteMeta } = this.props
    const symptoms = this.getCoronaSymptoms()

    if (!siteMeta || (siteMeta && !siteMeta.enableCoronaWarning)) return null

    return (
      <Wrapper>
        <Text size="xs">
          <h2>Vigtig information om Coronavirus/COVID-19</h2>

          <p>
            Spørgoda.dk's almindelige symptom-tjek screener ikke for
            Coronavirus. Hvis du har mistanke om at du har Corona, henviser vi
            til vores{' '}
            <Link href={routes.covid19}>
              <strong>COVID-19 screening</strong>
            </Link>
            , som kan hjælpe dig til at finde ud af hvordan du skal forholde dig
            til dine symptomer.
          </p>
          <p>Vi heviser desuden til sundhedsstyrelsens symptombeskrivelse:</p>
          <p>
            <strong>
              Symptomer på coronavirus/covid-19: Feber,
              luftvejsinfektion/vejrtrækningsproblemer, kulderystelser,
              muskelsmerter, ondt i halsen, hovedpine, diarré, kvalme/opkast.
              Det tager mellem to og 12 dage, fra du bliver smittet, til du
              bliver syg. (
              <a href="http://coronasmitte.dk">http://coronasmitte.dk</a>)
            </strong>
          </p>
          {symptoms.length > 0 && (
            <React.Fragment>
              <p>Du har angivet symptomer, som kan falde i denne kategori:</p>
              <Symptoms>
                {symptoms.map(symptom => (
                  <Symptom key={symptom.id}>
                    {symptom.name.charAt(0).toUpperCase() +
                      symptom.name.slice(1)}
                  </Symptom>
                ))}
              </Symptoms>
            </React.Fragment>
          )}
          <p>
            Vi anbefaler altid at du følger sundhedsstyrelsens anbefalinger og
            orienterer dig på{' '}
            <a
              href="http://coronasmitte.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              coronasmitte.dk
            </a>{' '}
            om hvordan, du skal forholde dig, hvis du har mistanke om, at du er
            smittet.
          </p>
        </Text>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withTriage(),
  withSiteMeta()
)(Results)

const Wrapper = styled.div`
  padding: 50px 0;
  border: 1px solid ${props => props.theme.colors.triage.emergency};
  border-top: 5px solid ${props => props.theme.colors.triage.emergency};
  background-color: #fff;
  padding: 30px;
  font-size: 20px;
  margin-bottom: 50px;
  text-align: left;
`

const Symptoms = styled.span`
  display: block;
  margin-bottom: 20px;
`

const Symptom = styled.span`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`

const Text = styled(TextComponent)`
  /* max-width: 700px;
  margin: 0 auto; */

  strong {
    font-style: italic;
    padding: 0;
    margin: 0;
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`
