import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, media, Row, Col } from 'styled-bootstrap-grid'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import RangeSlider from 'ui/components/generic/RangeSlider'

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.navHeight}px);
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: ${(props) => props.theme.navHeight}px;
`

const Age = styled(Text).attrs({
  size: 'xl',
})`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
`

const Section = styled.div`
  margin-bottom: 40px;

  input {
    text-align: center;
    font-size: 28px;
    width: 300px;

    ${media.xs`
      width: 100%;
      font-size: 20px;
    `}
  }
`

const minAge = 12
const maxAge = 108

class AgeSelect extends Component {
  static propTypes = {
    age: PropTypes.number,
    onChange: PropTypes.func,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      age: props.age || Math.floor((maxAge + minAge) / 2),
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleButtonInput)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleButtonInput)
  }

  handleButtonInput = (e) => {
    const { age } = this.state
    let newAge = age

    if (e.key === 'ArrowLeft') {
      newAge = age - 1
    }
    if (e.key === 'ArrowRight') {
      newAge = age + 1
    }

    if (newAge !== age && newAge >= minAge && newAge <= maxAge) {
      this.setState({ age: newAge })
    }

    if (e.key === 'Enter') {
      this.select()
    }
  }

  select = () => {
    const { onChange } = this.props
    const { age } = this.state

    if (age) {
      onChange(age)
    }
  }

  handleInput = (value) => this.setState({ age: value })

  render() {
    const { age } = this.state

    return (
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={8} mdOffset={2} lg={6} lgOffset={3}>
              <Section>
                <Text as="p">Angiv venligst din alder.</Text>
                <Age>{age} år</Age>
                <RangeSlider
                  onChange={this.handleInput}
                  min={minAge}
                  max={maxAge}
                  value={age}
                />
              </Section>
              <div>
                <Button onClick={this.select} disabled={!age} color="primary">
                  Videre
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default compose(withRouter)(AgeSelect)
