import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div``

class Page extends Component {
  static propTypes = {
    children: PropTypes.node
  }
  render() {
    return <Wrapper>{this.props.children}</Wrapper>
  }
}

export default Page
