import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithSubmitRating extends Component {
    render() {
      return (
        <Mutation
          mutation={gql`
            mutation submitRating {
              submitRating(input: $input)
                @rest(type: "Post", path: "rate", method: "POST") {
                success
              }
            }
          `}
        >
          {(submitRating, { loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    submitRating,
                    submitRatingLoading: loading,
                    submitRatingResult:
                      !loading && !error && data && data.submitRating
                  }}
                />
              )
            }
            return <p>Error: {error.message}</p>
          }}
        </Mutation>
      )
    }
  }
