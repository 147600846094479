import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './modules'
import localStorage, { loadPersistentState } from './middleware/localStorage'
import tracking from './middleware/tracking'

export default (server = false) => {
  const enhancers = []
  const middleware = [thunk, localStorage, tracking]

  let initialState = {}

  if (!server) {
    // Load persistent data
    initialState = loadPersistentState({})

    // Enable dev tools if in development mode
    if (process.env.NODE_ENV === 'development') {
      const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

      if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
      }
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  const store = createStore(rootReducer, initialState, composedEnhancers)

  return store
}
