import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconStar = ({ stroke, fill, ...rest }) => (
  <Svg
    width={512}
    height={492}
    viewBox="0 0 512 492"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fillRule="nonzero" fill="none">
      <path
        className="fill"
        d="M499.574 188.504c-3.199-9.922-11.988-16.938-22.398-17.899L335.82 157.762 279.926 26.926a26.019 26.019 0 00-23.934-15.766c-10.433 0-19.82 6.207-23.937 15.809l-55.89 130.816-141.38 12.84a25.938 25.938 0 00-22.379 17.879 26.028 26.028 0 007.617 27.648l106.86 93.696L95.37 448.62a26.055 26.055 0 0010.137 26.879 25.655 25.655 0 0015.23 4.992c4.63 0 9.239-1.234 13.356-3.71l121.898-72.895 121.875 72.875a25.959 25.959 0 0028.61-1.239 26.059 26.059 0 0010.132-26.882l-31.507-138.77 106.859-93.7a26.046 26.046 0 007.613-27.667z"
        fill={fill}
      />
      <path
        className="stroke"
        d="M114.617 491.137a27.161 27.161 0 01-15.957-5.184c-8.855-6.398-12.992-17.43-10.582-28.094l32.938-145.066L9.312 214.828a27.196 27.196 0 01-7.976-28.906 27.228 27.228 0 0123.402-18.73l147.82-13.419 58.41-136.746C235.279 6.98 245.09.492 255.993.492c10.903 0 20.715 6.488 25.024 16.512l58.41 136.77 147.797 13.417c10.882.98 20.054 8.344 23.425 18.711 3.372 10.387.254 21.739-7.98 28.907l-111.68 97.941 32.938 145.066c2.414 10.668-1.727 21.696-10.578 28.094-8.813 6.38-20.567 6.914-29.891 1.324l-127.465-76.16-127.445 76.203a27.021 27.021 0 01-13.93 3.86zm141.375-112.871c4.844 0 9.64 1.3 13.953 3.859l120.278 71.938-31.086-136.942a27.191 27.191 0 018.62-26.516l105.473-92.523-139.543-12.672a27.099 27.099 0 01-22.593-16.469L255.992 39.895 200.844 168.96c-3.903 9.238-12.563 15.531-22.59 16.43l-139.52 12.672 105.47 92.519c7.554 6.594 10.839 16.77 8.62 26.54l-31.082 136.94 120.278-71.937c4.328-2.559 9.128-3.86 13.972-3.86z"
        fill={stroke}
      />
    </g>
  </Svg>
)

const Svg = styled.svg`
  width: 30px;
  height: auto;

  path {
    transition: all 250ms;
  }
`

IconStar.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string
}

IconStar.defaultProps = {
  stroke: '#000',
  fill: 'transparent'
}

export default IconStar
