import { connect } from 'react-redux'
import {
  removeSymptom,
  addQuestion,
  updateSymptom,
  updateSymptoms,
  reset
} from 'redux/modules/symptoms'

export default connect(
  state => ({
    symptoms: state.symptoms.symptoms,
    answered: state.symptoms.answered,
    questions: state.symptoms.questions
  }),
  {
    removeSymptom,
    addQuestion,
    updateSymptom,
    updateSymptoms,
    reset
  }
)
