export const WINDOW_RESIZE = 'WINDOW_RESIZE'

export const RESET = 'RESET'

// Overlay
export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const HIDE_OVERLAY = 'HIDE_OVERLAY'

// Patient info
export const SET_AGE = 'SET_AGE'
export const SET_GENDER = 'SET_GENDER'
export const ACCEPT_TERMS = 'ACCEPT_TERMS'
export const IGNORE_EMERGENCY = 'IGNORE_EMERGENCY'

// Symptoms
export const REMOVE_SYMPTOM = 'REMOVE_SYMPTOM'
export const SET_CHOICE_ID = 'SET_CHOICE_ID'
export const ADD_QUESTION = 'ADD_QUESTION'
export const UPDATE_SYMPTOMS = 'UPDATE_SYMPTOMS'
