import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setGender } from 'redux/modules/patientInfo'

import routes from 'routes'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'

const Wrapper = styled.div`
  height: calc(100vh - ${props => props.theme.navHeight}px);
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: ${props => props.theme.navHeight}px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 10px;
  }

  ${media.xs`
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0 !important;
    }
  `}
`

class GenderSelect extends Component {
  static propTypes = {
    setGender: PropTypes.func,
    history: PropTypes.object,
    gender: PropTypes.string
  }

  select = gender => {
    const { setGender, history } = this.props
    setGender(gender)
    history.push('/covid19' + routes.patientInfo.ageSelect)
  }

  render() {
    const { gender } = this.props

    return (
      <Wrapper>
        <Container fluid>
          <Text as="p">Vælg venligst dit køn.</Text>
          <Buttons>
            <Button
              color={gender === 'male' ? 'primaryDark' : 'primary'}
              onClick={() => this.select('male')}
            >
              Mand
            </Button>
            <Button
              color={gender === 'female' ? 'primaryDark' : 'primary'}
              onClick={() => this.select('female')}
            >
              Kvinde
            </Button>
          </Buttons>
        </Container>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      gender: state.patientInfo.gender
    }),
    { setGender }
  )
)(GenderSelect)
