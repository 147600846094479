import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { reverse } from 'named-urls'

import withSymptoms from 'containers/withSymptoms'
import withPatientInfo from 'containers/withPatientInfo'
import withMedia from 'containers/withMedia'

import routes from 'routes'

import theme from 'ui/styles/theme'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'
import SearchInput from './SearchInput'
import InfoBar from './InfoBar'

const searchHeight = 60

class Symptoms extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
    }

    this.search = React.createRef()
  }

  state = {
    search: '',
  }

  getHint() {
    const symptoms = this.getInitialSymptoms()

    switch (symptoms.length) {
      case 0:
        return (
          <p>
            Hej, angiv de symptomer, du oplever ved hjælp af søgefeltet nedenfor
          </p>
        )
      case 1:
        return <p>Godt. Angiv gerne 1-2 symptomer mere</p>
      case 2:
        return <p>Oplever du andre symptomer?</p>
      default:
        return <p>Godt! Tryk på knappen nedenfor for at gå videre.</p>
    }
  }

  getInitialSymptoms = () => {
    const { symptoms } = this.props
    return symptoms.filter((s) => s.source === 'initial')
  }

  handleSearch = (search) => this.setState({ search })

  getSearchY() {
    const { windowHeight } = this.props
    const { search } = this.state

    let wrapperHeight = windowHeight - theme.navHeight - 60 // padding

    const viewTop = -(wrapperHeight / 2) + searchHeight / 2

    return search ? viewTop : 0
  }

  render() {
    const {
      symptoms,
      removeSymptom,
      breakpoint,
      windowHeight,
      onContinue,
    } = this.props
    const { search } = this.state

    const initialSymptoms = this.getInitialSymptoms()

    const hintVisible = !search || search.length === 0

    const symptomsVisible =
      initialSymptoms.length > 0 && (!search || search.length === 0)
    const infoBarVisible = !symptomsVisible && !search

    const searchY = this.getSearchY()

    return (
      <Wrapper>
        <PaddedContent>
          <Inner height={windowHeight}>
            <Hint visible={hintVisible}>{this.getHint()}</Hint>
            <Search y={searchY} ref={this.search}>
              <SearchInput
                symptoms={symptoms}
                onSearch={this.handleSearch}
                ref={this.search}
                autoSearch={breakpoint !== 'xs'}
              />
            </Search>
            <Actions visible={symptomsVisible}>
              <Text size="xs" mb={20}>
                Valgte symptomer
              </Text>
              <SelectedSymptoms>
                <SymptomScroller>
                  {initialSymptoms.map((symptom, index) => (
                    <Symptom
                      key={symptom.id}
                      onClick={() => removeSymptom(symptom.id)}
                      present={symptom.choiceId === 'present'}
                      style={{ order: initialSymptoms.length - index }}
                    >
                      {symptom.name}
                    </Symptom>
                  ))}
                </SymptomScroller>
              </SelectedSymptoms>
              <Button
                onClick={onContinue ? () => onContinue() : null}
                color="primary"
              >
                Videre
              </Button>
            </Actions>
          </Inner>
        </PaddedContent>
        <InfoBar visible={infoBarVisible} />
      </Wrapper>
    )
  }
}

Symptoms.propTypes = {
  symptoms: PropTypes.array,
  updateSymptom: PropTypes.func,
  removeSymptom: PropTypes.func,
  windowHeight: PropTypes.number,
  breakpoint: PropTypes.string,
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withMedia
)(Symptoms)

const Wrapper = styled.div``

const Inner = styled.div`
  height: ${(props) => props.height - props.theme.navHeight}px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: ${(props) => props.theme.navHeight}px;
`

const Search = styled.div`
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  margin-top: -${searchHeight}px;

  transform: translateY(${(props) => (props.y < 0 ? -100 : 0)}px);
  transition: 1000ms;

  ${media.xs`
    transform: translateY(${(props) => props.y}px);
  `}
`

const SelectedSymptoms = styled.div`
  margin-bottom: 20px;

  ${media.xs`
    position: relative;
    display: flex;
    justify-content: center;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      background: rgb(245,245,245);
      background: linear-gradient(90deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%);
      pointer-events: none;
    }
  `}
`

const SymptomScroller = styled.div`
  ${media.xs`
    display: flex;
    margin: auto;
    overflow-x: auto;
    padding: 15px 0;
  `}
`

const Symptom = styled(Text).attrs({ size: 'xxs' })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: nowrap;
  flex-shrink: 0;

  height: 40px;

  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px 10px 40px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 20px;
  max-width: 300px;
  margin: 5px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;

  ${media.xs`
    height: 30px;
    border-radius: 15px;
  `}

  &:before {
    content: '✕';
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 20px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 8px;
    /* background-color: blue; */
  }
`

const Hint = styled(Text).attrs({ size: 'md' })`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 50%;
  margin-bottom: ${searchHeight}px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : -200)}px);
  transition: 1000ms;

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Actions = styled.div`
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  margin-top: ${searchHeight / 2}px;

  transform: translateY(${(props) => (props.visible ? 0 : 30)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 500ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`
