import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { reverse } from 'named-urls'

import routes from 'routes'
import withSymptoms from 'containers/withSymptoms'
import withTriage from 'containers/withTriage'
import withPatientInfo from 'containers/withPatientInfo'
import withSiteMeta from 'containers/withSiteMeta'

import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

const targetSymptoms = [
  // Dårligere almen helbredstilstand
  // Træthed
  's_2100', // Føler mig træt, svag og døsig

  // Vægttab
  's_285',

  // Tørst
  's_310',

  // Tissetrang
  's_215',
  's_75',
  's_182',

  // Synsforstyrrelser
  's_124', // Sorte pletter i synsfeltet
  's_2041', // Synsforstyrrelse - ser farvede ringe
  's_207', // Dobbeltsyn

  // Kløe
  's_254', // Kløe

  // Infektion i hud og slimhinder
  's_505', // Hyppige infektioner
  's_672', // Sår på hud eller slimhinde
  's_1981', // Sårinfektion

  // Vanskelig heling af sår.
  'p_166' // Ikke-helende sår
]

class Results extends Component {
  static propTypes = {
    symptoms: PropTypes.array
  }

  getMatchingSymptoms() {
    const { symptoms } = this.props

    if (!symptoms) return []

    return symptoms.filter(
      s => s.choiceId === 'present' && targetSymptoms.includes(s.id)
    )
  }

  render() {
    const symptoms = this.getMatchingSymptoms()

    if (symptoms.length === 0) return null

    return (
      <Wrapper>
        <Header>
          <Text>Information om type 2-diabetes</Text>
        </Header>
        <Content>
          <Text size="xs">
            <p>
              Mange danskere går rundt med udiagnosticeret type 2-diabetes,
              hvilket forringer deres livskvalitet.
            </p>
            <p>
              Type 2-diabetes er arvelig. Men det er et komplekst samspil mellem
              gener og miljø, der er skyld i den øgede risiko, så sygdommen er
              ikke arvelig på samme måde som øjenfarve. Det vil sige, at dine
              forældre, helsøskende og børn er i forhøjet risiko for at få type
              2-diabetes, men ikke nødvendigvis får det. Overvægt øger risikoen.
            </p>

            <p>
              Symptomerne på type 2-diabetes kan være svære at opdage, fordi de
              ofte er diffuse og i begyndelsen er relativt svage. De udvikler
              sig desuden langsomt – lige fra måneder til år.
            </p>
            <p>
              <strong>Vær opmærksom på disse symptomer</strong>
              <ul>
                <li>Dårligere almen helbredstilstand</li>
                <li>Træthed</li>
                <li>Vægttab</li>
                <li>Tørst</li>
                <li>Tissetrang</li>
                <li>Synsforstyrrelser</li>
                <li>Kløe</li>
                <li>Infektion i hud og slimhinder</li>
                <li>Vanskelig heling af sår.</li>
              </ul>
            </p>
            <p>
              Du kan teste din risiko for at få type 2-diabetes ved en online
              risikovurdering:{' '}
            </p>
            <p>
              <Button
                color="primary"
                to={reverse(routes.diabetesRiskAssesment)}
              >
                Test min risiko
              </Button>
            </p>
          </Text>
        </Content>
        <Footer>
          <span>
            Læs mere på{' '}
            <a href="https://diabetes.dk">Diabetesforeningens hjemmeside →</a>
          </span>
          <MainLogo src="/images/df-logo.svg" />
        </Footer>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withTriage(),
  withSiteMeta()
)(Results)

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.tones.lightest};
  padding: 20px;
`

const Content = styled.div`
  padding: 40px;
`

const MainLogo = styled.img`
  width: 120px;
  height: auto;
`

const Footer = styled(Text).attrs({ size: 'xs' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dd020e;
  padding: 30px;

  &,
  a,
  svg path {
    fill: #fff;
    color: #fff;
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`
