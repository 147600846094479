import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import withSymptoms from 'containers/withSymptoms'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Explication from 'ui/components/generic/Explication'

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Question = styled(Text).attrs({ as: 'p', size: 'sm' })`
  margin-bottom: 30px
  text-align: center;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    flex-direction: row;
  `}

  button {
    margin-left: 0 !important;
    margin-bottom: 10px;

    ${media.md`
      margin-left: 10px !important;

      &:first-child {
        margin-left: 0 !important;
      }
    `}
  }
`

class SingleQuestion extends Component {
  static propTypes = {
    text: PropTypes.string,
    items: PropTypes.array,
    onAnswer: PropTypes.func,
    getChoiceId: PropTypes.func,
    explication: PropTypes.string,
  }

  render() {
    const { text, items, onAnswer, getChoiceId, explication } = this.props

    return (
      <PaddedContent>
        <Wrapper>
          <Question>
            {text}
            {explication && <Explication text={explication} />}
          </Question>
          {items.map((item) => (
            <Options key={item.id}>
              {item.choices.map((choice) => (
                <Button
                  key={choice.id}
                  id={choice.id}
                  onClick={() => onAnswer(item, choice.id)}
                  color={
                    getChoiceId(item) === choice.id ? 'primaryDark' : 'primary'
                  }
                >
                  {choice.label}
                </Button>
              ))}
            </Options>
          ))}
        </Wrapper>
      </PaddedContent>
    )
  }
}

export default withSymptoms(SingleQuestion)
