import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const useDiagnosis = (patientInfo, symptoms) => {
  const age = patientInfo.age
  const sex = patientInfo.gender
  const interviewId = patientInfo.interviewId
  const evidence = symptoms
    .filter((symptom) => !!symptom.choiceId)
    .map((symptom) => {
      const ev = {
        id: symptom.id,
        choice_id: symptom.choiceId,
      }

      // Only add source if it has a value
      if (symptom.source) {
        ev.source = symptom.source
      }

      return ev
    })

  const { loading, data } = useQuery(
    gql`
      query diagnosis {
        diagnosis(input: $input)
          @rest(type: "Diagnosis", path: "diagnosis/", method: "POST") {
          question @type(name: "Question") {
            type
            text
            explication
            instruction
            items @type(name: "QuestionItem") {
              id
              name
              explication
              instruction
              choices @type(name: "QuestionChoice") {
                id
                label
              }
            }
          }
          should_stop
          has_emergency_evidence
          conditions @type(name: "Condition") {
            id
            common_name
            name
            probability

            # details
            description
            descriptionSource
            link
            linkSource
            type
          }
        }
      }
    `,
    {
      variables: {
        input: {
          age: {
            value: age,
          },
          sex,
          evidence,
          interviewId,
          extras: { disable_groups: false, enable_explanations: true },
        },
      },
    }
  )

  return {
    loading,
    data: !loading && data?.diagnosis,
  }
}

export default useDiagnosis
