import React, { Component } from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import withSymptoms from 'containers/withSymptoms'
import withTriage from 'containers/withTriage'
import withCovid19 from 'containers/withCovid19'
import withTranslations from 'containers/withTranslations'
import withPatientInfo from 'containers/withPatientInfo'

import getTranslation from 'utils/getTranslation'
import Text from 'ui/components/generic/Text'

const Wrapper = styled.div`
  padding: 50px 0;
  border-top: 5px solid ${props => props.theme.colors.primary};
  background-color: #fff;
  padding: 30px;
  font-size: 20px;
  margin-bottom: 50px;
  text-align: left;
`

const Symptoms = styled.div`
  margin-top: 20px;
`
const SymptomsHeader = styled(Text).attrs({ size: 'xs' })`
  margin-bottom: 20px;
  font-weight: bold;
`

const Symptom = styled(Text).attrs({ size: 'sm' })`
  margin-bottom: 10px;
  font-weight: ${props => props.theme.fontWeights.light};
`

class Results extends Component {
  getText() {
    const {
      triage: { triage_level },
      covid19
    } = this.props

    return covid19[`triage__${triage_level}`]
  }

  render() {
    const { triage, translations } = this.props

    if (!triage || !translations) return null

    const { triage_level, serious } = triage

    const seriousLevels = [
      'emergency_ambulance',
      'emergency',
      'consultation_24',
      'no_risk',
      'self_monitoring',
      'quarantine',
      'isolation_call',
      'call_doctor',
      'isolation_ambulance'
    ]

    if (!seriousLevels.includes(triage_level)) return null

    const isEmergency = triage_level.indexOf('emergency') === 0

    const emergencySymptoms = serious.filter(s =>
      isEmergency ? s.is_emergency : true
    )

    return (
      <Wrapper level={triage_level}>
        <Text size="sm" dangerouslySetInnerHTML={{ __html: this.getText() }} />
        <Symptoms>
          {emergencySymptoms.length > 0 && (
            <SymptomsHeader>Alvorlige symptomer:</SymptomsHeader>
          )}
          {emergencySymptoms.map(symptom => (
            <Symptom key={symptom.id}>
              {getTranslation(symptom.id, translations) || symptom.common_name}
            </Symptom>
          ))}
        </Symptoms>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withPatientInfo,
  withSymptoms,
  withCovid19(),
  withTranslations(),
  withTriage(undefined, undefined, undefined, undefined, true)
)(Results)
