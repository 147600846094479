import React from 'react'

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="75"
    height="93"
    viewBox="0 0 75 93"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FEFEFE"
        d="M54.171 34.336a21.372 21.372 0 004.254-12.563c.071-5.745-2.091-11.172-6.089-15.285C48.339 2.377 42.985.073 37.261.002L36.992 0c-9.961 0-18.371 6.884-20.741 16.195l3.854 1.845c1.609-7.903 8.583-13.841 16.888-13.841l.216.001a17.106 17.106 0 0112.133 5.22 17.227 17.227 0 014.9 12.301 17.211 17.211 0 01-3.926 10.773l3.855 1.842zM0 68.182v23.217h4.184V68.182c0-8.051 4.945-14.958 11.944-17.843l-4.747-2.27C4.576 52.182 0 59.647 0 68.182zm16.564 23.217h4.184V73.315h-4.184v18.084zm-.219-45.606l6.541 3.127c.174-.005.344-.027.52-.027v-4.198c-2.461 0-4.831.388-7.061 1.098z"
      ></path>
      <path
        fill="#AEDEF8"
        d="M59.126 42.021c5.666 2.7 9.293 5.515 11.76 9.133 2.678 3.927 3.926 8.772 3.926 15.247v25.964h-4.184V66.401c0-10.524-3.48-15.91-13.296-20.588a40812.61 40812.61 0 01-43.083-20.605c-3.326-1.593-7.334-.178-8.935 3.153a6.712 6.712 0 00-.287 5.163 6.683 6.683 0 003.445 3.846L55.65 59.928l-1.8 3.79L6.674 41.16a10.847 10.847 0 01-5.592-6.243 10.894 10.894 0 01.465-8.38c2.6-5.407 9.107-7.702 14.504-5.118"
      ></path>
    </g>
  </svg>
)
