import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithTranslations extends Component {
    render() {
      return (
        <Query
          query={gql`
            query Covid19 {
              optionsCovid19 {
                introHeader
                introBody
                resultDisclaimer
                triage__no_risk
                triage__self_monitoring
                triage__quarantine
                triage__isolation_call
                triage__call_doctor
                triage__isolation_ambulance
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  covid19Loading={loading}
                  covid19={!loading && !error && data.optionsCovid19}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
