import React from 'react'
import theme from 'ui/styles/theme'

export default ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="101"
    height="15"
    viewBox="0 0 101 15"
  >
    <g fill="none">
      <path
        fill={color || theme.colors.primary}
        d="M.03 11.647c0-.885 1.12-1.858 1.238-1.77.767.708 2.271 1.386 3.569 1.386 1.12 0 1.68-.324 1.68-.944 0-.737-.973-.737-2.152-1.002C2.33 8.904.295 8.078.295 5.57c0-2.448 1.828-3.981 5.131-3.981 3.156 0 4.542 1.091 4.542 2.27 0 1.033-1.238 1.918-1.327 1.859C7.845 4.98 6.606 4.42 5.456 4.42c-1.062 0-1.622.324-1.622.944 0 .678.973.62 2.212.914 2.36.53 4.158 1.534 4.158 3.745 0 2.625-1.799 4.07-5.426 4.07-3.48 0-4.749-1.209-4.749-2.447zm15.129.56c0 1.091-.56 1.74-1.622 1.74h-.531c-1.003 0-1.563-.59-1.563-1.74V3.92c0-1.327.678-2.065 1.917-2.065h3.185c3.42 0 5.308 1.475 5.308 4.365 0 2.86-1.799 4.276-5.19 4.276h-1.445v1.71h-.06zm1.297-4.453c1.121 0 1.74-.56 1.74-1.534 0-1.032-.619-1.563-1.77-1.563H15.13v3.126h1.327v-.03zm18.669.088c0 3.628-2.625 6.282-6.341 6.282-1.327 0-2.477-.325-3.48-.914 0-.03-1.327 1.474-1.327 1.474-.118.148-1.445-.265-1.445-1.062 0-.235.118-.53.442-.884l.796-.885c-.855-1.062-1.327-2.418-1.327-4.01 0-3.599 2.625-6.253 6.341-6.253 1.298 0 2.477.324 3.45.914l1.24-1.386c.117-.147 1.415.236 1.415 1.062 0 .265-.118.53-.413.884l-.708.767c.885 1.062 1.357 2.448 1.357 4.011zm-5.102-2.92a3.198 3.198 0 00-1.239-.236c-1.829 0-2.92 1.269-2.92 3.127 0 .53.089 1.002.266 1.415l3.893-4.306zm-2.448 5.78c.354.148.767.237 1.238.237 1.77 0 2.95-1.268 2.95-3.126 0-.531-.089-1.003-.266-1.416l-3.922 4.306zm19.582 1.947c.118.148-.53 1.268-2.123 1.268-1.15 0-1.74-.501-2.507-1.563l-1.68-2.241h-.68v2.094c0 1.12-.56 1.74-1.621 1.74h-.531c-1.032 0-1.563-.796-1.563-2.153V3.92c0-1.357.678-2.065 1.917-2.065h3.716c3.332 0 5.013 1.386 5.013 3.952 0 1.74-.884 3.038-2.595 3.51l2.654 3.332zm-5.515-5.19c1.121 0 1.681-.502 1.681-1.386 0-.915-.53-1.416-1.71-1.416h-1.534v2.802h1.563z"
      ></path>
      <path
        fill={color || theme.colors.secondary}
        d="M61.844 7.842c0-3.627 2.654-6.252 6.34-6.252 3.687 0 6.342 2.625 6.342 6.252 0 3.657-2.625 6.282-6.341 6.282-3.716-.03-6.34-2.625-6.34-6.282zm8.995 0c0-1.77-1.061-2.979-2.684-2.979-1.622 0-2.654 1.15-2.654 2.98 0 1.769 1.062 2.948 2.684 2.948 1.593 0 2.654-1.18 2.654-2.949zm4.984 3.952V3.92c0-1.357.826-2.065 1.947-2.065h3.185c3.952 0 6.37 2.271 6.37 5.928 0 3.716-2.507 6.075-6.459 6.075h-2.978c-1.15-.03-2.065-.914-2.065-2.064zm4.955-1.12c1.74 0 2.89-1.062 2.89-2.89 0-1.8-1.091-2.803-2.831-2.803H79.51v5.692h1.268zm11.03.678l-.354.914c-.442 1.268-1.239 1.74-2.035 1.74-1.032 0-2.035-.826-1.917-1.15L91.395 2.77c.177-.62.678-1.033 2.389-1.033 1.68 0 2.123.413 2.3 1.033l4.04 10.027c.118.324-.884 1.18-1.975 1.18-.797 0-1.622-.414-2.124-1.77l-.324-.885h-3.893v.03zm3.185-2.655l-1.268-3.48-1.298 3.48h2.566z"
      ></path>
      <path
        fill={color || theme.colors.primary}
        d="M53.852 14.124c-3.48 0-5.721-2.595-5.721-6.193 0-3.628 2.624-6.253 6.576-6.253 3.008 0 4.748 1.18 4.748 2.773 0 1.327-1.77 1.799-1.858 1.68-.648-.825-1.622-1.268-2.683-1.268-1.71 0-3.038 1.328-3.038 3.274 0 1.976 1.21 3.244 2.89 3.244 1.327 0 2.183-.62 2.36-1.563h-1.24c-1.179 0-1.74-.265-1.74-1.268v-.177c0-1.062.59-1.268 1.74-1.268h3.274c1.062 0 1.298.501 1.269 1.563v5.013c0 .118-.59.384-1.239.384-.737 0-1.534-.295-1.829-1.416-.648.856-1.828 1.475-3.509 1.475z"
      ></path>
    </g>
  </svg>
)
