import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import colorAlpha from 'color-alpha'

import responsiveText from 'ui/styles/responsiveText'
import { media } from 'styled-bootstrap-grid'
import IconInfo from 'ui/components/generic/IconInfo'
import Text from 'ui/components/generic/Text'

class Button extends Component {
  static defaultProps = {
    hollow: undefined,
    component: undefined,
    explication: undefined
  }

  state = { explicationVisible: false }

  render() {
    const { component, explication, children, ...rest } = this.props
    const { explicationVisible } = this.state

    // If there's a "to"-prop use Link as default component - else use a
    const defaultComponent = !!this.props.to ? Link : 'button'

    return (
      <Wrapper
        as={component || defaultComponent}
        {...rest}
        onBlur={() => this.setState({ explicationVisible: false })}
      >
        {children}
        {explication && (
          <Explication
            color="#fff"
            onClick={e => {
              e.stopPropagation()
              this.setState({ explicationVisible: !explicationVisible })
            }}
          />
        )}
        {explication && (
          <PopUp
            visible={explicationVisible}
            onClick={e => {
              e.stopPropagation()
              this.setState({ explicationVisible: false })
            }}
          >
            <Text size="xs">{explication}</Text>
          </PopUp>
        )}
      </Wrapper>
    )
  }
}

Button.propTypes = {
  component: PropTypes.node,
  to: PropTypes.string,
  explication: PropTypes.string
}

export default Button

const Wrapper = styled.div`
  /* reset */
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  /* show a hand cursor on hover; some argue that we
  should keep the default arrow cursor for buttons */
  cursor: pointer;
  outline: none;

  &::-moz-focus-inner {
    border: none;
  }

  &:focus {
    outline: none;
  }

  position: relative;
  height: ${props => (props.size === 'small' ? 34 : 40)}px;
  min-width: ${props => (props.size === 'small' ? 100 : 150)}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: ${props => (props.size === 'small' ? 17 : 20)}px;
  border: 2px solid ${props =>
    props.color ? props.theme.colors[props.color] : '#fff'};
  background-color: ${props =>
    props.color ? props.theme.colors[props.color] : '#fff'};

  text-transform: uppercase;
  font-family: ${props => props.theme.bodyFont};
  font-weight: ${props => props.theme.fontWeights.normal};
  ${responsiveText('xxs')}

  transition: all 400ms;

  & + & {
    margin-left: 10px;

    ${media.xs`
      margin-top: 10px;
      margin-left: 0;
    `}
  }

  &, &:active, &:visited {
    color:  ${props => (props.color ? '#fff' : props.theme.colors.primary)};
    text-decoration: none;
  }

  ${media.sm`

    &:hover {
      background-color: ${props =>
        props.color
          ? colorAlpha(props.theme.colors[props.color], 0.8)
          : '#fff'};
    }
  `}


  ${props =>
    props.disabled &&
    css`
      &,
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    `}

  ${props =>
    props.compact &&
    css`
      min-width: 0;
    `}

  ${props =>
    props.round &&
    css`
      width: 40px;
      min-width: 0;
      border-radius: 50%;
      padding: 0;
    `}

  ${props =>
    props.hollow &&
    css`
      &,
      &:active,
      &:visited {
        background-color: transparent;
        color: ${props.color ? props.theme.colors[props.color] : '#fff'};
      }

      &:hover {
        background-color: ${props.color
          ? props.theme.colors[props.color]
          : '#fff'};
        color: ${props.color ? '#fff' : props.theme.colors.primary};
      }

      ${props.disabled &&
        css`
          &,
          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        `}
    `}
`

const Explication = styled(IconInfo)`
  margin-left: 10px;

  &:hover {
    transform: scale(1.1, 1.1);
  }
`

const PopUp = styled.div`
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 15px;
  transform: translateX(-50%);
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 500ms;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  color: #333;
  z-index: 10000;

  padding: 15px;
  background-color: #fff;
  width: auto;
  width: 320px;
  max-width: calc(100vw - 40px);
  text-transform: none;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`
