import React from 'react'
import PropTypes from 'prop-types'
import theme from 'ui/styles/theme'

const IconMinus = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
      <path
        fill={color || theme.colors.primary}
        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M17.96 13H7.04C6.466 13 6 12.552 6 12s.466-1 1.04-1h10.92c.574 0 1.04.448 1.04 1s-.466 1-1.04 1z"
      ></path>
    </g>
  </svg>
)

IconMinus.propTypes = {
  color: PropTypes.string
}

export default IconMinus
